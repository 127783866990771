import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import {
  HttpClient,
  HttpHeaders,
  HttpParams
} from '@angular/common/http';
import { AuthCustomService } from '../../authCustom.service';
import { AuthService } from '../../auth.service';
import { async } from 'rxjs';

export type pedidoModel = {

  _id?: string,
  id?: string,
  orderID?: string,
  customerID?: string,
  customerEmail?: string,
  customerName?: {
      firstName?: string,
      lastName?: string
  },
  phone?:string,
  code?:string,
  noticiasWA?: boolean,
  trackingIDs?: [string]
  gateways?: [string],
  totalPriceGiftCard?: number,
  totalPriceShipment?: number,
  lineItems?:any,
  prekitLineItems?:any,
  customerNote?:string,
  financialStatus?: string,
  appID?: number,
  paymentDate?: Date,
  paymentReceiveDate?: Date,
  createDate?: Date,
  expectDate?:Date,
  createdAt?: Date,
  updateDate?: Date,
  totalPrice?: number,
  totalPriceCustom?: number,
  estimatedDelivery?:Date,
  shippingMethod?: string,
  shippingAddress?: {
      address1?: string,
      address2?: string,
      city?: string,
      countryName?: string,
      countryCode?: string,
      latitude?: number,
      longitude?: number,
      phone?: string, 
      provinceName?: string,
      provinceCode?: string,
      zipCode?: string,
      firstName?: string,
      lastName?: string,
      company?: string,

  },
  checkoutID?: number,
  nota?: string,
  comments?:[{ message?: string, timestamp?: Date, operatorID?: string }],
  tags?: string[],
  prioridad?: number,
  objPrioridad?:{ 
      calFecha?: number, 
      calMolestia?: number, 
      calPeso?: number, 
      calTags?: number, 
      calnOrdenes?: number
  },
  totalDiscounts?: number,
  originPlatform?: string,
  prekitDate?: Date,
  fulfillmentDate?: Date,
  arrivalDate?: Date,
  casaVerdeDate?: Date,
  departureDate?:Date,
  prekitStartDate?:Date,
  embalajeStartDate?:Date,
  finishDate?:Date,
  cancelDate?: Date,
  locationID?: number,
  refunds?:[string],
  tip?:number,
  molestia?: number,
  ordersCount?:number,
  totalWeight?: number, 
  kgWeightOrder?: number, 
  volumetricWeightOrder?: number, 
  kgWeightReal?: number, 
  volumetricWeightReal?: number,
  shopifyVolumetricWeight?: number,
  joinedOrders?: [{id?: string, orderID?: string, createDate?: Date}],
  arrPartialPrekit?: [{uid?: string, operatorname?: string, createDate?: Date, lineItems: any[], lineItemsExit: []  }],
  packingInfo?:
   { operatorID?:string, 
      cajas?:[{sku?: string, price?: number, trackingId?: number, 
          master?: boolean,largo?: number,ancho?:number, alto?: number }], operatorName?:string, 
      priceEstafeta?: number, 
      priceFedex?: number,
      priceAMPM?: number,
      priceEShip?: number,
      priceDHL?: number,
  } ,
  prekitInfo?: { operatorID?: string, operatorName?: string },
  prekitStartInfo?: { operatorID?: string, operatorName?: string },
  addInfo?:any[]
  history?:[{ date?: Date, action?: Object, operatorUID?: string, operatorName?: string }],
  express?: boolean,
  shipment?: string,
  shipmentPrice?: number,
  shipmentCompany?: string,
  trackingInfo?:any,
  trackingHistory?:any,
  orderStatusURL?: string,
  ayudaMsgDate?: Date,
  promocionMsgDate?: Date,
  reviewMsgDate?: Date,
  recordarDate?:Date,
  credito?: number,
  transactions?:{
      gateway: string,
      amount: number,
      id: string,
      authorization: string, 
  }[],
  fee?:{
      gateway: string,
      amount: number,
      paymentMethodID: string,
      paymentTypeID: string,
  },
  noteAttributes?:{
      name: string;
      value: string;
  }[],
  source?: string,
  descuentoComercial?: number,
  company?:string,
  linkDrive?:string,
  reference?:string,

}    


// import { uuid } from 'uuidv4';
@Injectable({
  providedIn: 'root'
})



export class ArgusApiService {
  private headers = {};
  private currentUser;
  private url = environment.local ? environment.argus.apiUrlLocal : environment.argus.apiUrl;
  private urlMB = environment.argus.apiUrlMB;

  constructor(
    private http: HttpClient,
    private authCustom: AuthCustomService,
    private auth: AuthService
    ) {
  
    }

  getUser = async () => {
    return await this.auth.getUser();
  }

  crearOC = async(sku, qty, proveedorID, costo_unitario?) => {

    const currentUser = await this.auth.getUser();

    const headers = {
      Authorization: `Bearer ${currentUser.uid}`,
    };
    const endpoint =  '/compras/oc/crear';

    environment.local ?     environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'):
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/')
    


    return this.http.post<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'), {sku, qty, proveedorID, costo_unitario}, {headers});


  }

  getOCs = async( query ) => {

    const currentUser = await this.auth.getUser();

    const headers = {
      Authorization: `Bearer ${currentUser.uid}`,
    };
    const endpoint =  '/compras/ocs';

    return this.http.post<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'),query, {headers});


  }

  agregarProveedor = async(
    dataProveedor:{
      leadtime: number,
      contactoPrincipal:{
      
        correos:string[],
        nombre: string,
        telefono: string
  
      },
      contactoSecundario:{
        
        correos:string[],
        nombre: string,
        telefono: string
  
      },
      direccion: {
        address1: string,
        address2: string,
        city: string,
        phone: string,
        provinceName: string,
        zipCode: string,
        company: string,
      },
      correos:string[],
      correosComprobante:string[],
      correosOC:string[],
      waComprobantes:string[],
      credito: string ,
      razonSocial: string,
      rfc: string,
      telefono: string,
      tipoEntrega: string,
      reporte: {
        demanda: string,
        temporalidad: string
      },
      datosBancarios: {
        banco: string,
        numCuenta: string,
        clabe: string,
        beneficiario: string,
      }
    }
) => {

  const currentUser = await this.auth.getUser();

  const headers = {
    Authorization: `Bearer ${currentUser.uid}`,
  };
  const endpoint =  '/proveedores/scrt/agregarProveedor';

  let {        contactoPrincipal, contactoSecundario, correos, correosOC, credito, datosBancarios,
    direccion,leadtime, razonSocial, reporte, rfc, telefono, waComprobantes, tipoEntrega} = dataProveedor

  return this.http.post<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'),{contactoPrincipal, contactoSecundario, correos, correosOC, credito, datosBancarios,
    direccion,leadtime, razonSocial, reporte, rfc, telefono, waComprobantes, tipoEntrega}, {headers});


  }

  getEmpresas = async () => {
    const currentUser = await this.auth.getUser();

    const headers = {
      Authorization: `Bearer ${currentUser.uid}`,
    };
    const endpoint =  '/api/getEmpresas';

    return this.http.get<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'), {headers});

  }

  getBolsas = async () => {
    const currentUser = await this.auth.getUser();

    const headers = {
      Authorization: `Bearer ${currentUser.uid}`,
    };
    const endpoint =  '/productos/bolsas';

    return this.http.get<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'), {headers});

  }

  getPedidosActivos = async(area: 'almacen' | 'embalaje' | 'produccion' | 'sellado') => {
    
    const currentUser = await this.auth.getUser();

    const headers = {
      Authorization: `Bearer ${currentUser.uid}`,
    };
    const endpoint =  `/pruebas/getPedidosActivos/${area}`;

    return this.http.get<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'), {headers});


  } 


  getPedidosUnfulfilled = async() => {

    const currentUser = await this.auth.getUser();

    const headers = {
      Authorization: `Bearer ${currentUser.uid}`,
    };
    const endpoint =  '/logistica/pedidosUnfulfilled';

    return this.http.get<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'), {headers});

  }

  recalcularTablaBolsas = async() => {

    const currentUser = await this.auth.getUser();

    const headers = {
      Authorization: `Bearer ${currentUser.uid}`,
    };
    const endpoint =  '/api/productos/saveTablaBolsas';

    return this.http.get<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'), {headers});


  }

  getMP = async () => {
    const currentUser = await this.auth.getUser();

    const headers = {
      Authorization: `Bearer ${currentUser.uid}`,
    };
    const endpoint =  '/productos/mp';

    return this.http.get<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'), {headers});

  }



  empezarTurno = async () => {

    const endpoint = '/turnos/empezarTurno';

    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };

    return this.http.post<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'), {}, {headers: this.headers } );

  }
  terminarTurno = async () => {

    const endpoint = '/turnos/terminarTurno';

    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };

    return this.http.post<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'), {}, {headers: this.headers } )

  }

  getPedido = async (id) => {
    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };
    const endpoint = '/turnos/getPedido/' + id.replace('#', '');

    return this.http.get<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'), {headers: this.headers } );

  }
  

  getPedidoQuery = async (query) => {
    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };
    const endpoint = '/turnos/getPedidoQuery';

    return this.http.post<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'), query, {headers: this.headers } );

  }

  updatePedido = async (id, updateBody) => {
    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };
    const endpoint = '/api/updatePedido/' + id.replace('#', '');

    return this.http.post<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'),updateBody, {headers: this.headers } );

  }
  updateOM = async (id, updateBody) => {
    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };
    const endpoint = '/api/turnos/updateOM/' + id.replace('#', '');

    return this.http.post<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'),updateBody, {headers: this.headers } );

  }

  buscarPedido = async (id) => {

    const endpoint = '/turnos/asignarEmbalaje/' + id;

    return this.http.post<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'), {}, {headers: this.headers } );

  }

  getPedidoExists = async (query) => {

    const endpoint = '/logistica/getPedidoExists';
    
    return this.http.post<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'), query, {headers: this.headers } );

  }


  asignarPrekit = async (id, area) => {

    const endpoint = '/turnos/asignarPrekit/' + id;

    return this.http.post<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'), {areaManual: area}, {headers: this.headers } );

  }
  asignarPaquete = async (id) => {

    const endpoint = '/turnos/asignarPaquete/' + id;

    return this.http.get<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'), {headers: this.headers } );

  }


  pasarAlLimbo = async (id, area) => {

    const endpoint = '/turnos/pasarAlLimbo/' + id;

    return this.http.post<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'), {areaManual: area}, {headers: this.headers } );

  }
  pasarAlLimboPaquete = async () => {

    const endpoint = '/turnos/pasarAlLimboPaquete';

    return this.http.get<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'), {headers: this.headers } );

  }

  printPedido = async (id) => {

    const endpoint = '/turnos/printPedido/' + id;

    return this.http.get<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'), {headers: this.headers } );

  }

  getNextPedido = async () => {

    const endpoint = '/turnos/getNextPedido';

    return this.http.post<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'), {}, {headers: this.headers } );

  }

  // Funciones Archivos Amazon
  getArchivos = async (prefix) => {
    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };

    const endpoint = '/api/archivos/listPDF';

    return this.http.post<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'), {prefix}, {headers: this.headers});
  }

  setArchivo = async (string, folder, id, fileName) => {
    
    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };

    const endpoint = '/turnos/archivos/subirPDF';

    return this.http.post<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'), {string, folder, id, fileName}, {headers: this.headers});

  }



  subirComprobanteOC = async (string, folder, id, total) => {

    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };

    const endpoint = `/finanzas/archivo/subirComprobanteOC/${id}`;

    return this.http.post<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'), { string, folder, total }, {headers: this.headers});

  }

  subirFactura = async (string, folder, id, fileName) => {

    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };

    const endpoint = '/finanzas/subirFactura';

    return this.http.post<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'), {string, folder, id, fileName}, {headers: this.headers});

  }

  subirComprobante = async (string, folder, id, fileName) => {

    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };

    const endpoint = '/finanzas/subirComprobante';

    return this.http.post<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'), {string, folder, id, fileName}, {headers: this.headers});

  }

  borrarArchivo = async (Key, borrarTransaction = false) => {
    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };
    const endpoint = '/api/archivos/borrarPDF';
    // console.log(Key)
    return this.http.post<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'), {Key, borrarTransaction}, {headers: this.headers});
  }

  armar = async (id) => {

    const endpoint = '/turnos/armarPedido/' + id;
    console.log('Armando ', id);

    return this.http.post<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'), {}, {headers: this.headers } );

  }

  armarIncompleto = async (id, lineItems) => {

    const endpoint = '/turnos/armarPedidoIncompleto/' + id;
    console.log('Armando ', id);

    return this.http.post<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'), {lineItems}, {headers: this.headers } );

  }

  armarPedidoManual = async (id) => {
    
    const endpoint = `/turnos/armarPedidoManual/${id}`;

    return this.http.post<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'), {}, {headers: this.headers } );
  }

  terminarPedido = async (id) => {

    const endpoint = '/turnos/terminarPedido/' + id;

    return this.http.post<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'), {}, {headers: this.headers } );

  }

  getCajas = async () => {

    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };
    console.log('getCajas');

    const endpoint = '/api/turnos/getCajas';

    return this.http.get<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'), {headers: this.headers } );


  }

  getMerch = async () => {

    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };
    console.log('getMerch');

    const endpoint = '/api/turnos/getMerch';

    return this.http.get<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'), {headers: this.headers } );


  }

  getTablaEmbalaje = async () => {
    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };

    const endpoint = '/turnos/tablaEmbalaje';

    return this.http.get<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'), {headers: this.headers } );

    
  }

  getTablaEmbalajePaquetes = async () => {
    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };

    const endpoint = '/turnos/getPaquetesPorEmpacar';

    return this.http.get<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'), {headers: this.headers } );

    
  }
  
  getPedidosPaginated = async(query, page = 0) => {
    
    const user = await this.auth.getUser();
  
    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };
  
    const endpoint = `/turnos/pedidosPaginated/${page}`;
  
    return this.http.post<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'),query, {headers: this.headers } );
  
    

  }

  printGuias = async (id, cajas, merch, prueba?) => {
    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };

    const obj = {
      cajas, merch, prueba
    };

    const endpoint = '/turnos/terminarPedido/' + id;

    return this.http.post<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'), obj, {headers: this.headers } );


  }

  customEndpointPOSTMB  = async(endpoint, body)=> {

    const user = await this.auth.getUser();
  
    this.headers = {
      Authorization: `Bearer uetnTk0pxbgY4hQAxGQcLubHf253`,
    };
  
    return this.http.post<any>(this.urlMB + endpoint, body , {headers: this.headers } );
    

  }
  customEndpointGETMB  = async(endpoint)=> {

    const user = await this.auth.getUser();
  
    this.headers = {
      Authorization: `Bearer uetnTk0pxbgY4hQAxGQcLubHf253`,
    };
  
    return this.http.get<any>(this.urlMB + endpoint, {headers: this.headers } );
    

  }

  // crearClienteArgus= async() => {



  // }

  customEndpointPOST  = async(endpoint, body)=> {

    const user = await this.auth.getUser();
  
    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };
  
    return this.http.post<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'), body , {headers: this.headers } );
    

  }
  customEndpointGET  = async(endpoint)=> {

    const user = await this.auth.getUser();
  
    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };
  
    return this.http.get<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'), {headers: this.headers } );
    

  }

  getTablaLogistica = async () => {
    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };

    const endpoint = '/turnos/tablaLogistica';

    return this.http.get<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'), {headers: this.headers } );

  }


  prueba = () => {

    const headers = { Authorization: 'Bearer ', 'Content-Type': 'application/json', responseType: 'text', Accept: 'application / json'};
    this.http.post<any>('https://us-central1-cv-argus.cloudfunctions.net/api/pruebapost', {}, {headers}).subscribe(data => {

    });

  }

  getTablaPrekit = async (page = 0) => {

    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };
    const endpoint = `/turnos/pedidosPrekitPaginated/${page}`;

    return this.http.get<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'), {headers: this.headers} );

  }
  getStackPrekit = async () => {

    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };
    const endpoint = `/turnos/stackPrekit`;

    return this.http.get<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'), {headers: this.headers} );

  }
  getEnPrekit = async (page = 0) => {

    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };
    const endpoint = `/turnos/pedidosEnPrekit/${page}`;

    return this.http.get<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'), {headers: this.headers} );

  }



  getTablaPartialPrekit = async (page = 0) => {

    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };
    const endpoint = `/turnos/pedidosPartialPrekitPaginated/${page}`;

    return this.http.get<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'), {headers: this.headers} );
  }

  getPorEmpacar = async () => {

    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };

    const endpoint = `/logistica/porEmpacar`;

    return this.http.get<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'), {headers: this.headers});
  }

  registrarTablet = (deviceName) => {


    const endpoint = `/api/login`;

    return this.http.get<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'), {headers: this.headers});
  }

  registrarTablet2 = (deviceName) => {

    const obj = {
      deviceName
    };

    const endpoint = `/api/registrarTablet`;

    return this.http.post<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'), obj, {headers: this.headers});
  }

  login = async ( ) => {
    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };
    const endpoint = `/api/login`;

    return this.http.get<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'), {headers: this.headers});
  }


  getDemandaPt = async (limit) => {
    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };

    const endpoint = `/api/productos/getDemanda/${limit}`;

    return this.http.get<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'), {headers: this.headers});
  }

  getDemandaMp = async (limit) => {
    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };

    const endpoint = `/productos/demandaMp/${limit}`;

    return this.http.get<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'), {headers: this.headers});
  }

  // Funciones finanzas
  postTransferenciaEntreCuentas = async (de, a, cantidad, fecha) => {
    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };

    const obj = {
      de,
      a,
      cantidad,
      fecha
    };

    const endpoint = '/finanzas/transferenciaEntreCuentas';

    return this.http.post<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'), obj , {headers: this.headers } );
  }

  postIngresoCapital = async (cuenta, cantidad, referencia) => {
    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };

    const obj = {
      cuenta,
      cantidad,
      referencia
    };

    const endpoint = '/finanzas/ingresoCapital';

    return this.http.post<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'), obj , {headers: this.headers } );
  }

  postRetiroCapital = async (cuenta, cantidad, referencia) => {
    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };

    const obj = {
      cuenta,
      cantidad,
      referencia
    };

    const endpoint = '/finanzas/retiroCapital';

    return this.http.post<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'), obj , {headers: this.headers } );
  }


    postRegistrarGasto = async (cuenta,
      cantidad, 
      referencia, 
      categorias, 
      fecha: Date, 
      depreciacion: number,
      companyDivision,
      tagsDivision,
      impuesto,
      toPay?,
      retencion?,
      receiver?,
      assignDate?
      ) => {
      const user = await this.auth.getUser();

      this.headers = {
        Authorization: `Bearer ${user.uid}`,
      };
      
      const obj = {
        cuenta,
        cantidad,
        referencia,
        categorias,
        fecha,
        depreciacion,
        tagsDivision,
        companyDivision,
        impuesto,
        toPay,
        retencion,
        receiver,
        assignDate
      };
    
      const endpoint = '/finanzas/gasto';

      return this.http.post<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'), obj , {headers: this.headers } );
  }
    postRegistrarGastos = async (cuenta, cantidad, referencia, categorias, fecha: Date, 
      depreciacion: number,
      companyDivision,
      tagsDivision,
      impuesto,
      toPay,
      retencion,
      receiver,
      idsToPay,
      ocsToPay,
      assignDate?:Date,
      ocs = false
      ) => {
      const user = await this.auth.getUser();

      this.headers = {
        Authorization: `Bearer ${user.uid}`,
      };
      
      const obj = {
        cuenta,
        cantidad,
        referencia,
        categorias,
        fecha,
        depreciacion,
        tagsDivision,
        companyDivision,
        impuesto,
        toPay,
        retencion,
        receiver,
        idsToPay,
        ocsToPay,
        assignDate,
        ocs
      };
    
      const endpoint = '/finanzas/gastos';

      return this.http.post<any>(environment.local ? this.url + endpoint:
      this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'), obj , {headers: this.headers } );
  }

    postRegistrarTransaccion = async (
      sender: string, 
      receiver: string, 
      cantidad: number, 
      referencia: string, 
      categorias: string[], 
      fecha, 
      concepto, 
      depreciacion, 
      activo ) => {
      const user = await this.auth.getUser();

      this.headers = {
        Authorization: `Bearer ${user.uid}`,
      };
      
      const obj = {
        sender,
        receiver,
        cantidad,
        referencia,
        categorias,
        fecha,
        concepto,
        depreciacion,
        activo
      };
    
      const endpoint = '/finanzas/nuevaTransaccion';

      return this.http.post<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'), obj , {headers: this.headers } );
  }

    postRegistrarGastoDirector = async (cuenta, cantidad, referencia,  director: string, fecha: Date) => {
      const user = await this.auth.getUser();

      this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };
      const obj = {
      cuenta,
      cantidad,
      referencia,
      
      director,
      fecha,
    };

      const endpoint = '/finanzas/gastoDirector';

      return this.http.post<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'), obj , {headers: this.headers } );
  }

  postRegistrarRetiroDirector = async (cantidad, director: string,) => {
      const user = await this.auth.getUser();

      this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };

    const obj = {
      cantidad,
      director,
    };

      const endpoint = '/finanzas/retiroDirector';

      return this.http.post<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'), obj , {headers: this.headers } );
  }

  postPagarOC = async (cuenta, idOC, cantidad) => {
    
    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };

    const obj = {
      cuenta,
      idOC,
      cantidad,
    };

    const endpoint = '/finanzas/pagarOC';

    return this.http.post<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'), obj , {headers: this.headers } );
  }

  postMarcarComoPagadoOC = async (idOC) => {
    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };

    const obj = {
      idOC,
    };

    const endpoint = '/finanzas/marcarComoPagadoOC';

    return this.http.post<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'), obj , {headers: this.headers } );
  }

  postborrarMovimiento = async (id) => {
    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };

    const obj = {
      id
    };

    console.log(obj);

    const endpoint = '/finanzas/borrarMovimiento';

    return this.http.post<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'), obj , {headers: this.headers } );
  }

  postModificarMovimiento = async (_id, body) => {
    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };

    const obj = {
      _id,
      body
    };

    console.log(obj);

    const endpoint = '/finanzas/modificarMovimiento';

    return this.http.post<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'), obj , {headers: this.headers } );
  }

  // Funciones Turnos/Almacén General

  getTablaPrekitOM = async () => {
    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };

    const endpoint = `/turnos/getStackPrekit`;

    return this.http.get<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'), {headers: this.headers});
  }

  getTablaToStock = async () => {
    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };

    const endpoint = `/turnos/getStackToStock`;

    return this.http.get<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'), {headers: this.headers});
  }

  startPrekitOM = async(id: string) => {

    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };

    const endpoint = `/turnos/startPrekitOM/${id}`;

    return this.http.get<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'), {headers: this.headers});

  }

  postPrekitOM = async (id, bom) => {
    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };

    const obj = {
      bom,
    };

    const endpoint = `/turnos/prekitOM/${id}`;

    return this.http.post<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'), obj, {headers: this.headers});
  }

  getOM = async (id) => {
    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };

    const endpoint = `/turnos/getOM/${id}`;

    return this.http.get<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'), {headers: this.headers});
  }

  postMarcarLlegadaOC = async (id, arr) => {
    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };

    const obj = {
      arr
    };

    const endpoint = `/turnos/recibirOC/${id}`;

    return this.http.post<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'), obj , {headers: this.headers } );
  }

  postAsignarOM = async (id, area) => {
    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };

    const obj = {
      areaManual: area
    };

    const endpoint = `/turnos/asignarOM/${id}`;

    return this.http.post<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'), obj , {headers: this.headers } );
  }

  postPasarOMAlLimbo = async (id, area) => {
    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };

    const obj = {
      areaManual: area
    };

    const endpoint = `/turnos/pasarAlLimboOM/${id}`;

    return this.http.post<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'), obj , {headers: this.headers } );
  }

  postArmarOM = async (id, area,qtyProduction?, bom?, nota?, processOM?) => {
    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };

    const obj = {
      bom,
      qtyProduction,
      nota,
      areaManual: area,
      procesTimeInfo: processOM
    };

    console.log(obj);

    const endpoint = `/turnos/armarOM/${id}`;

    return this.http.post<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'), obj , {headers: this.headers } );
  }
  
  checarOM = async () => {
    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };


    const endpoint = `/turnos/checarOM`;

    return this.http.get<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/') , {headers: this.headers } );
  }

  postNewUser = (data) => {

    this.headers = {
      Authorization: `Bearer Hx1AMVm6KiX0sOH6p3I34sUggSs1`,
    };


    const endpoint = `/api/crearUsuario`;

    return this.http.post<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'), data , {headers: this.headers } );

  }

  postMermaOM = async (id, qty, sku, replace, nota, razon, responsibleUID?, itemMerma?) => {
    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
      ['x-idempotence-key']: ''
    };

    const obj = {
      qty,
      sku,
      replace,
      razon,
      responsibleUID,
      nota,
      itemMerma
    };

    console.log(obj);

    const endpoint = `/turnos/mermaOM/${id}`;

    return this.http.post<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'), obj , {headers: this.headers } );
  }

  getPedidosShipment = async(shipment: 'GUADALAJARA' | 'JR' | 'Estafeta' | 'MLIBRE' | 'JR' | 'FedEx' ) => {

    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };

    const endpoint = `/logistica/pedidosShipment/${shipment}`;

    return this.http.get<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/') , {headers: this.headers } );

  }

  recogerPedido = async(id) => {

    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };

    const endpoint = `/logistica/recogerPedido/${id.replace('#', '')}`;

    return this.http.get<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/') , {headers: this.headers } );

  }

  fulfillOrderManual = async(orderID, email, orderName) => {

    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };

    const endpoint = `/turnos/fulfillOrderManual/${orderID}}`;

    const body = {
      email, orderName
    }

    return this.http.post<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'), body, {headers: this.headers } );

  }

  salidaPedidoEspecial = async(id, 
    guia, 
    total,
    subtotal,
    retencion,
    impuesto,
    proveedor,
    fechaSalida
    ) => {

    const user = await this.auth.getUser();

    const body = {
      guia,
      total,
      subtotal,
      retencion,
      impuesto,
      proveedor,
      fechaSalida
    }

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };

    const endpoint = `/logistica/salidaPedidoEspecial/${id.replace('#', '')}`;

    return this.http.post<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/') ,body, {headers: this.headers } );

  }

  entregarPedido = async(id, receiverName, fecha) => {

    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };

    const obj = {
      receiverName,
      fecha
    }

    const endpoint = `/logistica/entregarPedido/${id.replace('#', '')}`;

    return this.http.post<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'), obj, {headers: this.headers } );

  }
  
  entregarPedidoCasaVerde = async(id, receiverName) => {

    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };

    const obj = {
      receiverName
    }

    const endpoint = `/logistica/entregarPedidoCasaVerde/${id.replace('#', '')}`;

    return this.http.post<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'), obj, {headers: this.headers } );

  }

  // Funciones Producción
  getTablaProduccion = async () => {
    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };

    const endpoint = `/turnos/getStackProduccion`;

    return this.http.get<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'), {headers: this.headers});
  }

  getProductionResumeByOperadorId = async ( operatorId, start, end ) => {
    const ednpoit = `/turnos/getProductionResume/${operatorId}`;
    const params = { start, end };
    this.headers = {
      Authorization: `Bearer ${operatorId}`,
    };
    
    return this.http.get<any>(this.url + ednpoit, {headers: this.headers, params });
  }

  postMermaMp = async () => {
    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };

    const obj = {

    };

    const endpoint = `/turnos/armarOM`;

    return this.http.post<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'), obj , {headers: this.headers } );
  }

  // Funciones Sellado
  getTablaSellado = async () => {
    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };

    const endpoint = `/turnos/getStackSellado`;

    return this.http.get<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'), {headers: this.headers});
  }

  getNextOM = async () => {
    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };

    const endpoint = `/produccion/getNextOM`;

    return this.http.get<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'), {headers: this.headers});
  }
  recibirOC = async (id, arr) => {
    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };

    const endpoint = `/compras/recibirOC/${id}`;

    return this.http.post<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'), {arr}, {headers: this.headers});

  }

  postCrearOM = async (sku, qty, tags = ['manual']) => {
    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };

    const endpoint = `/produccion/om/crear`;

    return this.http.post<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'), {sku, qty, tags }, {headers: this.headers});


  }

  postCrearOC = async (sku, qty, proveedorID, tags = []) => {
    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };

    const endpoint = `/compras/oc/crear`;

    return this.http.post<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'), {sku, qty, proveedorID, tags}, {headers: this.headers});


  }
  getPT = async () => {
    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };

    const endpoint = `/api/productos/getPT`;

    return this.http.get<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'), {headers: this.headers});


  }


  getPaquetes = async () => {
    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };

    const endpoint = `/turnos/getPaquetes`;

    return this.http.get<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'), { headers: this.headers });


  }

  getOrdenPaquete = async (id) => {
    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };

    const endpoint = `/turnos/getOrdenPaquete/${id}`;

    return this.http.get<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'), { headers: this.headers });


  }

  // Funciones Inventario Equipos

  postAsignarEquipo = async (codigoDeBarras, categoria, area, descripcion, createDate, operatorID, date, accion, numSerie) => {
    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };

    const obj = {
      codigoDeBarras,
      categoria,
      area,
      descripcion,
      createDate,
      operatorID,
      date,
      accion,
      numSerie
    };

    console.log(obj);

    const endpoint = `/api/inventarioEquipos/newEquipo`;

    return this.http.post<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'), obj , {headers: this.headers } );
  }

  getEquipos = async (query) => {

    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };

    const obj = {
      query,
    };


    const endpoint = `/api/inventarioEquipos/getEquipos`;

    return this.http.post<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'), obj , {headers: this.headers } );


  }

  // Funciones inventario cíclico

  registrarInvCiclicoPT = async (sku, qty) => {
    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };

    const obj = {
      sku,
      qty
    };

    const endpoint = `/turnos/registrarInvCiclicoPT`;

    return this.http.post<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'), obj, {headers: this.headers});
  }



  startCheckingInvCiclicoPT = async () => {
    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };

    const obj = {
      
    };

    const endpoint = `/turnos/startCheckingInvCiclicoPT`;

    return this.http.post<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'), obj, {headers: this.headers});
  }

  registrarInvCiclicoMP = async (sku, qty) => {
    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };

    const obj = {
      sku,
      qty
    };

    const endpoint = `/turnos/registrarInvCiclicoMP`;

    return this.http.post<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'), obj, {headers: this.headers});
  }

  

  startCheckingInvCiclicoMP = async () => {
    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };

    const obj = {
      
    };

    const endpoint = `/turnos/startCheckingInvCiclicoMP`;

    return this.http.post<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'), obj, {headers: this.headers});
  }

  getSkusInvCiclicoActivosConUser = async () => {
    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };

    const obj = {
      
    };

    const endpoint = `/turnos/getSkusInvCiclicoActivosConUser`;

    return this.http.post<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'), obj, {headers: this.headers});
  }


  postStartChecking = async (sku) => {
    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };

    const obj = {
      sku,
    };

    const endpoint = `/api`;

    return this.http.post<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'), obj, {headers: this.headers});
  }

  getPzsPorProducir = async () => {
    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };


    const endpoint = `/produccion/getPzsPorProducirReales`;

    return this.http.get<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'), {headers: this.headers});
  }


  pedidosPromedioAlDia = async () => {
    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };


    const endpoint = `/logistica/pedidosPromedioAlDia`;

    return this.http.get<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'), {headers: this.headers});
  }

  getOMs = async(page = 1) => {

    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };


    const endpoint = `/turnos/omsPaginated/${page}`;

    return this.http.get<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'), {headers: this.headers});

  }


  getOIs = async(page = 1) => {

    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };


    const endpoint = `/compras/getStackOIsPaginated/${page}`;

    return this.http.get<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'), {headers: this.headers});

  }

  crearOIs = async( arrSKUs, arrBolsasChina, proveedor: 'RICARDO' | 'FORIMPRE' ) => {

    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };


    const endpoint = `/compras/crearOIs`;

    return this.http.post<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'), { arrSKUs, arrBolsasChina, proveedor }, {headers: this.headers});

  }
  recibirOI = async(id, qty ) => {

    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };


    const endpoint = `/compras/recibirOI/${id}`;

    return this.http.post<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'), { qty }, {headers: this.headers});

  }

  ajusteInventario = async(arr)=> {

    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };


    const endpoint = `/api/productos/ajusteInventario`;

    return this.http.post<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'), arr , {headers: this.headers});


  }

  ajusteInventarioCasaVerde = async(arr)=> {

    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };


    const endpoint = `/api/productos/ajusteInventarioCasaVerde`;

    return this.http.post<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'), arr , {headers: this.headers});


  }
  apagarInvCiclicoPT = async(horario: 'Vespertino' | 'Matutino')=> {

    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };


    const endpoint = `/api/apagarInvCiclicoPT`;

    return this.http.post<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'), { horario: horario } , {headers: this.headers});


  }
  apagarInvCiclicoMP = async(horario: 'Vespertino' | 'Matutino')=> {

    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };


    const endpoint = `/api/apagarInvCiclicoMP`;

    return this.http.post<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'), { horario: horario } , {headers: this.headers});


  }
  prenderInvCiclicoPT = async(horario: 'Vespertino' | 'Matutino')=> {

    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };


    const endpoint = `/api/prenderInvCiclicoPT`;

    return this.http.post<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'), { horario: horario } , {headers: this.headers});


  }
  prenderInvCiclicoMP = async(horario: 'Vespertino' | 'Matutino')=> {

    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };


    const endpoint = `/api/prenderInvCiclicoMP`;

    return this.http.post<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'), { horario: horario } , {headers: this.headers});


  }

  getInfoProveedor = async(id) => {

    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };
    const endpoint = `/proveedores/getProveedor/${id}`;

    return this.http.get<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/') , {headers: this.headers});

  }

  getDemandaPaquetes = async(limit) => {

    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };
    const endpoint = `/api/productos/getDemandaPaquetes/${limit}`;

    return this.http.get<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/') , {headers: this.headers});


  }

  getAllProveedores = async() => {

    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };
    const endpoint = `/proveedores/getAllProveedores`;

    return this.http.get<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/') , {headers: this.headers});

  }

  // getOCs = async(query: object) => {
  //   console.log('query :>> ', query);
  //   const user = await this.auth.getUser();

  //   this.headers = {
  //     Authorization: `Bearer ${user.uid}`,
  //   };

  //   const endpoint = `/proveedores/getOCs`;

  //   return this.http.post<any>(    environment.local ? this.url + endpoint:
    //  this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'), query , {headers: this.headers});

  // }

  confirmarOC = async(id, qty) => {


    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };

    const endpoint = `/compras/oc/confirmar/${id}`;

    return this.http.post<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'), {qty} , {headers: this.headers});

  }

  enviarOC = async(id, qty) => {


    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };

    const endpoint = `/compras/oc/enviar/${id}`;

    return this.http.post<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'), {qty} , {headers: this.headers});

  }

  armarPaquete = async(sku, qty) => {

    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };

    const endpoint = `/turnos/armarPaquete/${sku}`;

    return this.http.post<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'), {qty} , {headers: this.headers});


  }

  terminarPaquete = async(id,  cajas:{sku: string, qty:number}[]) => {

    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };

    const endpoint = `/turnos/terminarPaquete/${id}`;

    return this.http.post<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'), {cajas} , {headers: this.headers});

  }
  
  desarmarPaquete = async(sku, qty) => {

    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };

    
    const endpoint = `/turnos/desarmarPaquete/${sku}`;

    return this.http.post<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'), {qty} , {headers: this.headers});


  }

  cambiarEstadoPaquete = async(sku) => {

    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };


    const endpoint = `/turnos/cambiarEstadoPaquete/${sku}`;

    return this.http.get<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'), {headers: this.headers});


  }

  cambiarEstadoMaquina = async(sku) => {

    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };


    const endpoint = `/api/cambiarEstadoMaquina/${sku}`;

    return this.http.get<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'), {headers: this.headers});


  }

  empezarOC = async( id, accion: 'MarcarLlegada' | 'Recibir' | 'PasarAlAlmacen' ) => {

    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };


    const endpoint = `/compras/oc/empezar/${id}`;

    return this.http.post<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'),{ accion },{headers: this.headers});


  }

  terminarOC = async( id, accion: 'MarcarLlegada' | 'Recibir' | 'PasarAlAlmacen', qty, 
  costo?:{
    qty:number,
    reference: string,
  } ) => {

    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };


    const endpoint = `/compras/oc/terminar/${id}`;

    return this.http.post<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'),{ accion, qty },{headers: this.headers});


  }

  regresarOC = async( id, accion: 'MarcarLlegada' | 'Recibir' | 'PasarAlAlmacen' ) => {

    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };


    const endpoint = `/compras/oc/regresar/${id}`;

    return this.http.post<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'),{ accion },{headers: this.headers});


  }

  pasarAlLimboOC = async( id, accion: 'MarcarLlegada' | 'Recibir' | 'PasarAlAlmacen' ) => {

    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };


    const endpoint = `/compras/oc/limbo/${id}`;

    return this.http.post<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'),{ accion },{headers: this.headers});


  }

  updateOC = async( id, update) => {  

    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };


    const endpoint = `/compras/oc/update/${id}`;

    return this.http.post<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'),update,{headers: this.headers});


  }


  getPedidos = async(query:object ) => {  
    
    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };


    const endpoint = `/turnos/getPedidos`;

    return this.http.post<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'), query,{headers: this.headers});


  }
  getPedidosSelect = async(query:object, select:object ) => {  
    
    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };


    const endpoint = `/turnos/getPedidosSelect`;

    return this.http.post<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'), {query, select},{headers: this.headers});


  }

  getCountPedidos = async(query:object ) => {  
    
    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };


    const endpoint = `/api/getCountPedidos`;

    return this.http.post<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'), query,{headers: this.headers});


  }

  getCountOM = async(query:object ) => {  
    
    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };


    const endpoint = `/api/getCountOM`;

    return this.http.post<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'), query,{headers: this.headers});


  }

  getCountOC = async(query:object ) => {  
    
    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };


    const endpoint = `/api/getCountOC`;

    return this.http.post<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'), query,{headers: this.headers});


  }

  deleteUser = async(uid ) => {  
    
    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };


    const endpoint = `/apiproduction/deleteUser/${uid}`;

    return this.http.get<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'),{headers: this.headers});


  }

  cancelarOC = async(id) => {

    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };

    const endpoint = `/compras/oc/cancelar/${id}`;

    return this.http.get<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'),{headers: this.headers});

  }

  agregarSKUsInvCiclico = async(skus:string[], idFecha: string, area: 'PT' | 'MP' ) =>{

    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };


    const endpoint = `/apiproduction/agregarSKUsInvCiclico/${idFecha}`;

    return this.http.post<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'),{skus, area},{headers: this.headers});


  }
  agregarSKUsInvCiclicoPorcentaje = async(porcentaje: number, idFecha: string, area: 'PT' | 'MP' ) =>{

    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };


    const endpoint = `/apiproduction/agregarSKUsInvCiclicoPorcentaje/${idFecha}`;

    return this.http.post<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'),{porcentaje, area},{headers: this.headers});


  }

  updateDoc = async( collection, doc, update )=>{
    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };


    const endpoint = `/api/updateDoc/${collection}/${doc}`;

    return this.http.post<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'), update, {headers: this.headers});


  }

  getDoc = async( collection, doc )=>{
    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };


    const endpoint = `/api/getDoc/${collection}/${doc}`;

    return this.http.get<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'), {headers: this.headers});


  }
  getPzsProducidas = async( start,end )=>{
    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };


    const endpoint = `/produccion/getPzsProducidas`;

    return this.http.post<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'), {start, end}, {headers: this.headers});


  }
  getPzsSelladas = async( start,end )=>{
    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };


    const endpoint = `/produccion/getPzsSelladas`;

    return this.http.post<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'), {start, end}, {headers: this.headers});


  }

  crearOrdenesEmpaques = async(rows, idProveedor) => {
    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };


    const endpoint = `/compras/crearOrdenesEmpaques`;

    return this.http.post<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'), {rows, idProveedor}, {headers: this.headers});

  
  }

  getAllTransactionsQtys = async(start?: Date, end? : Date) => {
    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };

    const endpoint = `/finanzas/getAllTransactionsQtys`;

    return this.http.post<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'), {start, end}, {headers: this.headers});

  
  }

  getPedidosPrekiteadosPorPersona = async(start: Date, end : Date) => {
    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };

    const endpoint = `/api/getPedidosPrekiteadosPorPersona`;

    return this.http.post<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'), {start, end}, {headers: this.headers});

  
  }

  getPedidosEmpacadosPorPersona = async(start: Date, end : Date) => {
    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };

    const endpoint = `/api/getPedidosEmpacadosPorPersona`;

    return this.http.post<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'), {start, end}, {headers: this.headers});

  
  }

  getOMProducidosPorPersona = async(start: Date, end : Date) => {
    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };

    const endpoint = `/api/getOMProducidasPorPersona`;

    return this.http.post<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'), {start, end}, {headers: this.headers});

  
  }

  getOMSelladosPorPersona = async(start: Date, end : Date) => {
    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };

    const endpoint = `/api/getOMSelladosPorPersona`;

    return this.http.post<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'), {start, end}, {headers: this.headers});

  
  }
  
  getDistribuidoresActivos = async(dias) => {
    
    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };

    const endpoint = `/comercial/getDistribuidoresActivos/${dias}`;

    return this.http.get<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'), {headers: this.headers});

  

  }
  getDistribuidoresActivosFecha = async(start, end) => {
    
    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };

    const endpoint = `/comercial/getActivosCanal`;

    return this.http.post<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'),{start, end}, {headers: this.headers});

  

  }

  readNotification = async(id) => {


    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };

    const endpoint = `/notifications/readNotification/${id}`;

    return this.http.get<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'),{headers: this.headers});

  }

  getNotificationsUser = async(uid?) => {


    const user = await this.auth.getUser();


    uid = uid || user.uid

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };

    const endpoint = `/notifications/getNotificationsUser/${uid}`;

    return this.http.get<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'),{headers: this.headers});

  }
  
  crearNotificacion = async(toUserID, text, title, area,url,  expirationDate,) => {


    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };

    let body = {
      toUserID, text, title, area,url,  expirationDate,
    }

    const endpoint = `/notifications/crearNotificacion`;

    return this.http.post<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'),body,{headers: this.headers});

  }

  getMesaProduccion = async(idFecha, mesa) => {


    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };

    const endpoint = `/produccion/getMesa/${idFecha}/${mesa}`;

    return this.http.get<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'),{headers: this.headers});

  
  }
  nuevaMesaProduccion = async(idFecha, mesa, personas:{operatorID: string, operatorName: string}[]) => {

    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };

    const body = {
      idFecha, 
      mesa,
      personas,
    }

    const endpoint = `/produccion/agregarMesa`;

    return this.http.post<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'), body,{headers: this.headers});

  
  }

  getCajasPorPaqueteria = async() => {

    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };

    const endpoint = `/logistica/getCajasPorPaqueteria`;

    return this.http.get<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'),{headers: this.headers});


  }

  getCuentasPorCobrar = async() => {

    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };

    const endpoint = `/finanzas/getCuentasPorCobrar`;

    return this.http.get<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'),{headers: this.headers});



  }

  getCuentasPorCobrarCuentas = async() => {

    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };

    const endpoint = `/finanzas/getCuentasPorCobrarCuentas`;

    return this.http.get<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'),{headers: this.headers});

  }


  getIngresos = async(start: Date, end: Date) => {

    const user = await this.auth.getUser();

    let obj = {
      start, 
      end,
    }

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };

    const endpoint = `/finanzas/getIngresos`;

    return this.http.post<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'),obj,{headers: this.headers});



  }

  getGastos = async(start: Date, end: Date) => {

    const user = await this.auth.getUser();

    let obj = {
      start, 
      end,
    }

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };

    const endpoint = `/finanzas/getGastos`;

    return this.http.post<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'),obj,{headers: this.headers});



  }

  getCuentasPorPagar = async() => {

    const user = await this.auth.getUser();


    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };

    const endpoint = `/finanzas/getCuentasPorPagar`;

    return this.http.get<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'),{headers: this.headers});
  }
  getCuentasPorPagarLista = async() => {

    const user = await this.auth.getUser();


    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };

    const endpoint = `/finanzas/getCuentasPorPagarLista`;

    return this.http.get<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'),{headers: this.headers});
  }

  getCuentasPorPagarPaqueterias = async() => {

    const user = await this.auth.getUser();


    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };

    const endpoint = `/finanzas/getCuentasPorPagarPaqueterias`;

    return this.http.get<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'),{headers: this.headers});
  }

  agregarPedidoArgus = async(pedido: pedidoModel) => {

    const user = await this.auth.getUser();


    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };

    const endpoint = `/pedidos/agregarPedidoArgus`;

    return this.http.post<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'),pedido,{headers: this.headers});

  }

  getCuentasPorPagarDesglose = async() => {

    const user = await this.auth.getUser();


    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };

    const endpoint = `/finanzas/getCuentasPorPagarDesglose`;

    return this.http.get<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'),{headers: this.headers});



  }

  getValorDeInventario = async() => {

    const user = await this.auth.getUser();


    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };

    const endpoint = `/finanzas/getValorDeInventario`;

    return this.http.get<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'),{headers: this.headers});



  }

  getSetMesas = async() => {

    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };

    const endpoint = `/produccion/getSetMesas`;

    return this.http.get<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'),{headers: this.headers});



  }

  saveSellosProveedor = async (id,body) => {
    const endpoint = `/proveedores/scrt/saveSellos/${id}`;
    const currentUser = await this.auth.getUser();

    const headers = {
      Authorization: `Bearer ${currentUser.uid}`,
    };
    return this.http.post<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'), body, {headers});
  }

  getAllMps = async () => {
    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };

    const endpoint = `/mp/getMps`;

    return this.http.get<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'),{headers: this.headers});
  }

  getAllPts = async () => {
    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };

    const endpoint = `/pt/getPts`;

    return this.http.get<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'),{headers: this.headers});
  }

  getAllPaquetes = async () => {
    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };

    const endpoint = `/turnos/getPaquetes`;

    return this.http.get<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'),{headers: this.headers});
  }

  getAllCustomers = async () => {
    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };

    const endpoint = `/customers/getCustomers`;

    return this.http.get<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'),{headers: this.headers});
  }

  getCustomers = async ( query ) => {
    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };

    const endpoint = `/customers/getCustomers`;

    return this.http.post<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'), query,{headers: this.headers});
  }

  getCustomersSelect = async ( query, select ) => {
    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };

    const endpoint = `/customers/getCustomersSelect`;

    return this.http.post<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'), {query, select},{headers: this.headers});
    
  }
 
  getClientesAutoservicio = async ( ) => {
    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };

    const endpoint = `/turnos/getClientesAutoservicio`;

    return this.http.get<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'),{headers: this.headers});

  }

  getAllTags = async () => {
    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };

    const endpoint = `/pedidos/getTags`;

    return this.http.get<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'),{headers: this.headers});
  }
  getDesgloseCategorias = async( start: Date, end: Date ) => {

    const body = {start, end}
    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };
    const endpoint = '/finanzas/getDesgloseCategorias';

    return this.http.post<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'), body, {headers: this.headers } );

  }

  createPedidos = async (body) => {
    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };

    const endpoint = `/pedidos/createOrder`;
    return this.http.post<any>(    environment.local ? this.url + endpoint: this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'), body, {headers: this.headers});
  }

  updateProveedor = async (id,body) => {
    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };

    const endpoint = `/proveedores/updateProveedor/${id}`;
    return this.http.put<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'), body, {headers: this.headers});
  }

   deleteProveedor=async (id)=>{
    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };

    const endpoint = `/proveedores/deleteProveedor/${id}`;
    return this.http.put<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'), {}, {headers: this.headers});
  }

   getPoints=async (body)=>{
    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };

    const endpoint = `/produccion/getOmsMesaInfo`;
    return this.http.post<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'), body, {headers: this.headers});
  }

   getOmsPuntos=async(start,end,gasto)=>{
    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };

    let body = {

      start,
      end,
      gasto

    }


    const endpoint = `/produccion/getOmsPuntos`;
    
    return this.http.post<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'),body,{headers: this.headers});
  }

   getTiempoFulfillPromedio=async(start,end)=>{
    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };

    let body = {

      start,
      end

    }


    const endpoint = `/apiproduction/getTiempoFulfillPromedio`;
    
    return this.http.post<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'),body,{headers: this.headers});
  
    }

   getPedidosEnVerdeWU=async()=>{
    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer uetnTk0pxbgY4hQAxGQcLubHf253`,
    };



    const endpoint = `/pruebas/getPedidosEnVerde`;
    
    return this.http.get<any>(this.urlMB + endpoint,{headers: this.headers});
  }

  mermaProducto = async(
    body:{
      sku: string,
      qty: number,
      razon: string,
      key?: string

    }
  ) => {

    body.key = body.key || 'stock_planta_1';

    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };

    const endpoint = `/turnos/nuevaMerma`;
    
    return this.http.post<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'),body,{headers: this.headers});
  

  } 

  notaCredito = async(
    body:{
      oc: string,
      total: number,
      referencia: string,
      fecha?:Date

    }
  ) => {


    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };

    const endpoint = `/finanzas/notaCredito`;
    
    return this.http.post<any>(    environment.local ? this.url + endpoint:
      this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'),body,{headers: this.headers});
  

  } 

  descuentoComercial = async(
    body:{
      id: string,
      total: number,
      referencia: string,
      fecha?:Date
    }
  ) => {


    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };

    const endpoint = `/finanzas/descuentoComercial`;
    
    return this.http.post<any>(    environment.local ? this.url + endpoint:
     this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'),body,{headers: this.headers});
  

  } 

  getOC = async(id) => {

    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };

    const endpoint = `/finanzas/getOC/${id}`;
    
    return this.http.get<any>(    environment.local ? this.url + endpoint:
      this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'),{headers: this.headers});
  
  }

  getClientesCredito = async() => {

    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };

    const endpoint = `/finanzas/getClientesCredito`;
    
    return this.http.get<any>(    environment.local ? this.url + endpoint:
      this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'),{headers: this.headers});
  
  }

  getDevolucionesPedidos = async() => {

    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };

    const endpoint = `/turnos/getDevolucionesPedidos`;
    
    return this.http.get<any>(    environment.local ? this.url + endpoint:
      this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'),{headers: this.headers});
  
  }

  aceptarMovimientoStock = async(id, qty) => {

    const user = await this.auth.getUser();

    this.headers = {
      Authorization: `Bearer ${user.uid}`,
    };

    const endpoint = `/turnos/aceptarMovimiento`;

    const body = {
      id,
      qty,
    }
    
    return this.http.post<any>(    environment.local ? this.url + endpoint:
      this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'), body, {headers: this.headers});
  
  }

  registrarDevolucion = async(id:string,
    arrSkus:{
        sku:string,
        qty: number,
    }[],
    moneyTransactions:{
        qty:number,
        sender: number
    }[],
    returnExpenses:{
        qty: number,
        reference: string,
        concept: string,
    }[],) => {

      const user = await this.auth.getUser();

      this.headers = {
        Authorization: `Bearer ${user.uid}`,
      };
  
      const endpoint = `/finanzas/getClientesCredito`;
      
      return this.http.post<any>(    environment.local ? this.url + endpoint:
        this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'),{
          id,
          arrSkus,
          moneyTransactions,
          returnExpenses,
        },{headers: this.headers});
    

  }

  getDocsByWhere = async() => {

    
  }



}

