import { Component } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html'
})
export class FooterComponent  {

  month = new Date().getMonth();
  day = new Date().getDate();
  year = new Date().getFullYear();

  constructor() { }

}
