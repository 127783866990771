// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import { UserRole } from '../app/shared/auth.roles';

// export NODE_OPTIONS='--openssl-legacy-provider'

export const environment = {
  notifications: false,
  local: false,
  production: false, 
  SCARF_ANALYTICS : false,
  adminRoot: '/app',
  apiUrl: 'https://api.coloredstrategies.com',
  buyUrl: 'url',
  defaultMenuType: 'menu-hidden',
  subHiddenBreakpoint: 1440,
  menuHiddenBreakpoint: 768,
  themeColorStorageKey: 'vien-themecolor',
  isMultiColorActive: true,
  defaultColor: 'light.redruby',
  isDarkSwitchActive: true,
  defaultDirection: 'ltr',
  themeRadiusStorageKey: 'vien-themeradius',
  isAuthGuardActive: true,
  defaultRole: UserRole.admin,
  argusSettings:{
    
  },
  argus: {
    apiUrlMB: 'https://us-central1-argus-mb.cloudfunctions.net',
    apiUrl: 'https://functionName-qswqkvpqma-uc.a.run.app',
    apiUrlOdoo: 'https://us-central1-cv-argus3.cloudfunctions.net',
    apiUrlOdoo4: 'https://us-central1-cv-argus3.cloudfunctions.net',
    apiUrlLocal: 'http://localhost:5001/cv-argus3/us-central1'
  },
  kroni: {
    apiUrl: 'https://kroni3pl--development.gadget.app',
    apiUrlLocal: 'https://kroni3pl--development.gadget.app'
  },
  firebase: {
    // apiKey: 'AIzaSyDakuvksTb9JOE_VtA7vVKyl68ChmuZrlE',
    // authDomain: 'cv-argus.firebaseapp.com',
    // databaseURL: 'https://cv-argus.firebaseio.com',
    // projectId: 'cv-argus',
    // storageBucket: 'cv-argus.appspot.com',
    // messagingSenderId: '1024548467957',
    // appId: '1:1024548467957:web:ab16b54d49687f74dd01bc',
    // measurementId: 'G-5QW8MWCQFF'
    apiKey: 'AIzaSyDDlMYDk2Mlh4M94SwSknkzqm-TOsI3Q6s',
    authDomain: 'cv-argus3.firebaseapp.com',
    databaseURL: 'https://cv-argus3.firebaseio.com',
    projectId: 'cv-argus3',
    storageBucket: 'cv-argus3.appspot.com',
    messagingSenderId: '921765428663',
    appId: '1:921765428663:web:22aa2b6b650b40cfb03c32',
    measurementId: 'G-DNQDK360Q3'
  }
};

