import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { from } from 'rxjs';
import { getUserRole } from 'src/app/utils/util';

export interface ISignInCredentials {
  email: string;
  password: string;
}

export interface ICreateCredentials {
  email: string;
  password: string;
  displayName: string;
}

export interface IPasswordReset {
  code: string;
  newPassword: string;
}

@Injectable({ providedIn: 'root' })

export class AuthService {
  constructor(private auth: AngularFireAuth, private db: AngularFirestore) {}

  // tslint:disable-next-line:typedef
  async signIn(credentials: ISignInCredentials) {
    const { user } = await this.auth
      .signInWithEmailAndPassword(credentials.email, credentials.password);
    return user;
  }

  // tslint:disable-next-line:typedef
  signOut() {
    return from(this.auth.signOut());
  }

  // tslint:disable-next-line:typedef
  async register(credentials: ICreateCredentials) {
    const { user } = await this.auth
      .createUserWithEmailAndPassword(credentials.email, credentials.password);
    user.updateProfile({
      displayName: credentials.displayName,
    });
    this.auth.updateCurrentUser(user);
    return await user;
  }

  // tslint:disable-next-line:typedef
  async sendPasswordEmail(email) {
    await this.auth.sendPasswordResetEmail(email);
    return true;
  }

  // tslint:disable-next-line:typedef
  async resetPassword(credentials: IPasswordReset) {
    const data = await this.auth
      .confirmPasswordReset(credentials.code, credentials.newPassword);
    return data;
  }

  // tslint:disable-next-line:typedef
  async getUser() {
    const u = await this.auth.currentUser;
    return { ...u, role: getUserRole() };
  }
}
