<div id="root">
    <div class="fixed-background">
        <main>
            <div class="container">
                <div class="row h-100">
                    <div class="col-12 col-md-10 mx-auto my-auto">
                        <div class="card auth-card">
                            <div class="position-relative image-side ">
                                <p class=" text-white h2">MAGIC IS IN THE DETAILS</p>
                                <p class="white mb-0">Yes, it is indeed!</p>
                            </div>
                            <div class="form-side">
                                <a routerLink="/">
                                    <span class="logo-single"></span>
                                </a>
                                <h6 class="mb-4">{{ "unauthorized.title" | translate }}</h6>
                                <p class="mb-0 text-muted text-small mb-0">
                                    {{ "unauthorized.detail" | translate }}
                                </p>
                                <p class="display-1 font-weight-bold mb-5">401</p>
                                <a routerLink="/app" class="btn btn-primary btn-lg btn-shadow" type="submit">
                                  {{ "pages.go-back-home" | translate }}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </div>
</div>