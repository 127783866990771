<div class="fixed-background"></div>
<main>
  <div class="container">
    <div class="row h-100">
      <div class="col-12 col-md-10 mx-auto my-4">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</main>
