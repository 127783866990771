import { environment } from 'src/environments/environment';
/* import { UserRole } from '../shared/auth.roles'; */

const adminRoot = environment.adminRoot;
const rolAlmacen = 'almacen_pt';
const rolEmbalaje = 'embalaje';
const rolAlmacenManual = 'almacen_pt_manual';
const rolPedidos = 'pedidos';
const rolProduccion = 'produccion';
const rolFinanzas = 'finanzas';
const rolFacturas = 'facturas';
const rolECC = 'ecc';
const rolContador = 'contador';
const rolPresupuestos = 'presupuestos';
const rolCobrar = 'cobrar';
const rolAlmacenGeneral = 'almacen_general';
const rolSellado = 'sellado';
const rolSupervisionProduccion = 'supervision_produccion';
const rolAutoservicio = 'autoservicio';
const rolProductos = 'productos';
const rolCompras = 'compras';
const rolProveedores = 'proveedor';
const rolSoporte = 'soporte';
const rolChofer = 'chofer';
const rolAcciones= 'acciones'
const rolCasaVerde = 'casa_verde'
const rolPanelOperacion = 'paneloperacion'
const rolPanelDeOperacion = 'paneldeoperacion'
const rolPanelFinanzas = 'panelfinanzas'
const rolPanelDeControl = 'paneldecontrol'
const rolComercial = 'comercial'
const rolMapa = 'mapa'
export interface IMenuItem {
  id?: string;
  icon?: string;
  label: string;
  to: string;
  newWindow?: boolean;
  subs?: IMenuItem[];
  roles?: any[];
}

const data = [
  // **** Menu Principal **** //
    // **** Menu Admin **** //
  {
    icon: 'iconsminds-king-2',
    label: 'Admin',
    to: `${adminRoot}/colaboradores`,
    roles: [rolPanelOperacion, rolPanelDeControl, rolComercial, rolPanelDeOperacion],
    subs: [
      {
        icon: 'iconsminds-affiliate',
        label: 'Colaboradores',
        to: `${adminRoot}/colaboradores/operadores`,
        roles: [],
      },
      {
        icon: 'iconsminds-qr-code',
        label: 'Código de barras',
        to: `${adminRoot}/colaboradores/barcode`,
        roles: [],
      },
      // {
      //   icon: 'iconsminds-soundcloud',
      //   label: 'Leer código',
      //   to: `${adminRoot}/colaboradores/leer-barcode`,
      //   roles: [],
      // },
      {
        icon: 'iconsminds-control',
        to: `${adminRoot}/admin/panelfinanzas`,
        label: 'Panel de Finanzas',
        roles: [rolPanelFinanzas],
      },
      {
        icon: 'iconsminds-control',
        label: 'Panel de Operación',
        to: `${adminRoot}/admin/paneldeoperacion`,
        roles: [rolPanelOperacion, rolPanelDeOperacion],
      },
      {
        icon: 'iconsminds-control-2',
        label: 'Panel de Control',
        to: `${adminRoot}/admin/paneldecontrol`,
        roles: [rolPanelDeControl],
      },
      {
        icon: 'iconsminds-money-bag',
        label: 'Panel de Comercial',
        to: `${adminRoot}/admin/panelcomercial`,
        roles: [rolComercial],
      },
      {
        icon: 'iconsminds-gear-2',
        label: 'Ajustes',
        to: `${adminRoot}/colaboradores/ajustes`,
        roles: [],
      },
      {
        icon: 'iconsminds-pricing',
        label: 'Precios guías',
        to: `${adminRoot}/admin/paqueterias`,
        roles: [],
      },
    ],
  },
  {
    icon: 'iconsminds-green-energy',
    label: 'Dashboards',
    to: `${adminRoot}/inicio`,
    roles: [rolSupervisionProduccion],
    subs: [
      // {
      //   icon: 'iconsminds-home',
      //   label: 'Home',
      //   to: `${adminRoot}/inicio/home`,
      // },
      {
        icon: 'iconsminds-biotech',
        label: 'Dashboard General',
        to: `${adminRoot}/inicio/dashboard`,
      },
      {
        icon: 'iconsminds-biotech',
        label: 'Dashboard Produccion',
        to: `${adminRoot}/inicio/dashboard-produccion`,
      },
      {
        icon: 'iconsminds-box-close',
        label: 'Dashboard Embalaje',
        to: `${adminRoot}/embalaje/dashboard`,
      },
      {
        icon: 'iconsminds-box-close',
        label: 'Dashboard Venta',
        to: `${adminRoot}/comercial/venta`,
        roles: [rolComercial],
      },
      // {
      //   icon: 'iconsminds-bar-chart-4',
      //   label: 'Reportes',
      //   to: `${adminRoot}/inicio/reportes`,
      //   roles: [],
      // },
    ],
  },
  // **** Menu Pedidos **** //
  {
    icon: 'iconsminds-scooter',
    label: 'menu.pedidos',
    to: `${adminRoot}/pedidos`,
    roles: [rolPedidos, rolSoporte, rolChofer, rolAcciones, rolAutoservicio],
    subs: [
      {
        icon: 'iconsminds-gear',
        label: 'Acciones',
        to: `${adminRoot}/pedidos/acciones`,
        roles: [rolAcciones],
      },
      {
        icon: 'iconsminds-add-cart',
        label: '+ pedido autoservicio',
        to: `${adminRoot}/pedidos/agregarautoservicio`,
        roles: [rolPedidos, rolSoporte, rolAcciones, rolAutoservicio],
      }, 
      {
        icon: 'iconsminds-add-cart',
        label: '+ pedido preliminar',
        to: `${adminRoot}/pedidos/agregar`,
        roles: [rolPedidos, rolSoporte, rolAcciones, rolAutoservicio],
      },
      {
        icon: 'iconsminds-add-cart',
        label: 'Levantar muestras',
        to: `${adminRoot}/pedidos/muestras`,
        roles: [rolPedidos, rolSoporte, rolAcciones, rolAutoservicio],
      },
      {
        icon: 'iconsminds-support',
        label: 'Soporte',
        to: `${adminRoot}/pedidos/soporte`,
        roles: [rolSoporte, rolPedidos],
      },
      {
        icon: 'iconsminds-jeep',
        label: 'Ruta GDL',
        to: `${adminRoot}/pedidos/guadalajara`,
        roles: [rolSoporte, rolPedidos, rolChofer ],
      },
      {
        icon: 'iconsminds-jeep',
        label: 'Pedidos Especiales (JR)',
        to: `${adminRoot}/pedidos/especiales`,
        roles: [rolSoporte, rolPedidos, rolChofer ],
      },
      {
        icon: 'iconsminds-home-1',
        label: 'Casa Verde',
        to: `${adminRoot}/pedidos/casaverde`,
        roles: [ rolCasaVerde, rolPedidos ],
      },
      {
        icon: 'simple-icon-notebook',
        label: 'Verificación de envíos',
        to: `${adminRoot}/pedidos/curation`,
        roles: [ rolPedidos ],
      },
      {
        icon: 'simple-icon-notebook',
        label: 'Autoservicio',
        to: `${adminRoot}/pedidos/autoservicio`,
        roles: [ rolPedidos, rolAutoservicio ],
      },
      {
        icon: 'simple-icon-notebook',
        label: 'Devolucion',
        to: `${adminRoot}/pedidos/devolucion`,
        roles: [ rolPedidos, rolAutoservicio ],
      },
    ],
  },
  // **** Menu Turnos **** //
  {
    icon: 'iconsminds-clock-forward',
    label: 'Turnos',
    to: `${adminRoot}/turnos`,
    roles: [rolAlmacen, rolEmbalaje, rolAlmacenManual, rolProduccion, rolAlmacenGeneral, rolSellado, rolSupervisionProduccion, rolAutoservicio, rolECC],
    subs: [
      {
        icon: 'iconsminds-female',
        label: 'Almacén General',
        to: `${adminRoot}/turnos/almacen-general`,
        roles: [rolAlmacenGeneral]
      },
      // {
      //   icon: 'iconsminds-gear-2',
      //   label: 'Producción',
      //   to: `${adminRoot}/turnos/produccion`,
      //   roles: [rolProduccion]
      // },
      // {
      //   icon: 'iconsminds-inbox-forward',
      //   label: 'Sellado',
      //   to: `${adminRoot}/turnos/sellado`,
      //   roles: [rolSellado]
      // },
      // {
      //   icon: 'iconsminds-engineering',
      //   label: 'Picking',
      //   to: `${adminRoot}/turnos/almacen-pt`,
      //   roles: [rolAlmacenManual, rolAlmacen]
      // },
      {
        icon: 'iconsminds-engineering',
        label: 'Picking Odoo',
        to: `${adminRoot}/turnos/picking-odoo`,
        roles: [rolAlmacenManual, rolAlmacen]
      },
      // {
      //   icon: 'iconsminds-male',
      //   label: 'Embalaje',
      //   to: `${adminRoot}/turnos/embalaje`,
      //   roles: [rolEmbalaje]
      // },
      {
        icon: 'iconsminds-male',
        label: 'Embalaje Odoo',
        to: `${adminRoot}/turnos/embalaje-odoo`,
        roles: [rolEmbalaje, rolAlmacenManual]
      },
      {
        icon: 'iconsminds-box-close',
        label: 'Paquetes',
        to: `${adminRoot}/turnos/paquetes`,
        roles: [rolAlmacenManual, rolAlmacen, rolProduccion,rolSupervisionProduccion ]
      },
      {
        icon: 'iconsminds-sunglasses-w-3',
        label: 'Supervisión',
        to: `${adminRoot}/turnos/supervision-produccion`,
        roles: [rolSupervisionProduccion]
      },
      {
        icon: 'iconsminds-office',
        label: 'Autoservicio',
        to: `${adminRoot}/turnos/autoservicio`,
        roles: [rolAutoservicio]
      },
      {
        icon: 'iconsminds-office',
        label: 'ECC',
        to: `${adminRoot}/turnos/ecc`,
        roles: [rolECC]
      },
    ],
  },
  // **** Menu Manufactura **** //
  {
    icon: 'iconsminds-factory',
    label: 'menu.manufactura',
    to: `${adminRoot}/manufactura`,
    roles: [rolSupervisionProduccion],
    subs: [
      {
        icon: 'iconsminds-add-file',
        label: 'menu.mfCrear',
        to: `${adminRoot}/manufactura/crear`,
        roles: [rolSupervisionProduccion],
      },
      {
        icon: 'iconsminds-receipt-4',
        label: 'Plan de produccion',
        to: `${adminRoot}/manufactura/plandeproduccion`,
        roles: [rolSupervisionProduccion],
      },
      {
        icon: 'iconsminds-mine',
        label: 'Maquina',
        to: `${adminRoot}/manufactura/maquina`,
        roles: [rolSupervisionProduccion],
      },
      // {
      //   icon: 'iconsminds-trash-with-men',
      //   label: 'menu.mfMerma',
      //   to: `${adminRoot}/manufactura/merma`,
      //   roles: [],
      // },
    ],
  },
  // **** Menu Compras **** //
  {
    icon: 'iconsminds-money-bag',
    label: 'Compras',
    to: `${adminRoot}/compras`,
    roles: [rolCompras],
    subs: [
      // {
      //   icon: 'iconsminds-dollar',
      //   label: 'Inicio',
      //   to: `${adminRoot}/compras/inicio`,
      //   roles: [],
      // },
      {
        icon: 'iconsminds-printer',
        label: 'Bolsas',
        to: `${adminRoot}/compras/bolsas`,
        roles: [rolCompras],
      },
      {
        icon: 'iconsminds-apple',
        label: 'MP',
        to: `${adminRoot}/compras/mp`,
        roles: [rolCompras],
      },
      {
        icon: 'iconsminds-shopping-bag',
        label: 'Empaque',
        to: `${adminRoot}/compras/empaques`,
        roles: [rolCompras],
      },
      {
        icon: 'iconsminds-paper',
        label: 'Crear OC',
        to: `${adminRoot}/compras/crear`,
        roles: [rolCompras],
      },

    ],
  },
    // **** Menu Productos **** //
    {
      icon: 'iconsminds-leafs',
      label: 'menu.producto',
      to: `${adminRoot}/productos`,
      roles: [rolProductos, rolAlmacenManual, rolAlmacen, rolCasaVerde],
      subs: [
        {
          icon: 'simple-icon-layers',
          label: 'menu.productoInv',
          to: `${adminRoot}/productos/inventario`,
          roles: [rolProductos, rolAlmacenManual, rolAlmacen, rolCasaVerde],
          subs: [
            {
              icon: 'iconsminds-apple',
              label: 'menu.mp',
              to: `${adminRoot}/productos/inventario/mp`,
              roles: [rolProductos, rolAlmacenManual],
            },
            {
              icon: 'iconsminds-can-2',
              label: 'menu.pt',
              to: `${adminRoot}/productos/inventario/pt`,
              roles: [rolProductos, rolAlmacenManual, rolAlmacen],
            },
            {
              icon: 'simple-icon-bag',
              label: 'menu.bolsas',
              to: `${adminRoot}/productos/inventario/bolsas`,
              roles: [rolProductos],
            },
            {
              icon: 'iconsminds-post-office',
              label: 'Casa Verde',
              to: `${adminRoot}/productos/inventario/casa-verde`,
              roles: [rolProductos,rolCasaVerde],
            },

          ],
        },
        {
          icon: 'iconsminds-biotech',
          label: 'Movimientos',
          to: `${adminRoot}/productos/movimientos`,
          roles: [rolProductos],
        },
        {
          icon: 'iconsminds-clock-forward',
          label: 'Inventario Cíclico',
          to: `${adminRoot}/productos/inventario-ciclico`,
          roles: [rolProductos],
        },
        {
          icon: 'iconsminds-financial',
          label: 'Rot. de productos',
          to: `${adminRoot}/productos/rotacion-productos`,
          roles: [rolProductos],
        },
        {
          icon: 'simple-icon-list',
          label: 'Lista',
          to: `${adminRoot}/productos/lista`,
          roles: [rolProductos],
        },
        // {
        //   icon: 'iconsminds-biotech',
        //   label: 'menu.productoSKU',
        //   to: `${adminRoot}/productos/sku`,
        //   roles: [],
        // },
        // {
        //   icon: 'iconsminds-biotech',
        //   label: 'menu.productoPO',
        //   to: `${adminRoot}/productos/porOrdenar`,
        //   roles: [],
        // },
      ],
    },
    // **** Menu Tablas **** //
    {
      icon: 'simple-icon-grid',
      label: 'Tablas',
      to: `${adminRoot}/tablas`,
      roles: [ rolProductos, rolComercial ],
      subs: [
        {
          icon: 'iconsminds-can-2',
          label: 'PT',
          to: `${adminRoot}/tablas/pt`,
          roles: [rolProductos, rolComercial],
        },
        {
          icon: 'iconsminds-can-2',
          label: 'Ordenar',
          to: `${adminRoot}/tablas/ordenar`,
          roles: [rolProductos, rolComercial],
        },
      ],
    },
  // **** Menu Órdenes **** //
  // {
  //   icon: 'iconsminds-file-clipboard-file---text',
  //   label: 'menu.orden',
  //   to: `${adminRoot}/ordenes`,
  //   roles: [],
  //   subs: [
  //     {
  //       icon: 'iconsminds-shop-2',
  //       label: 'Ordenes de compra',
  //       to: `${adminRoot}/ordenes/oc`,
  //       roles: []
  //     },
  //     {
  //       icon: 'iconsminds-printer',
  //       label: 'Ordenes de impresión',
  //       to: `${adminRoot}/ordenes/oi`,
  //       roles: []
  //     },
  //     {
  //       icon: 'iconsminds-biotech',
  //       label: 'Crear',
  //       to: `${adminRoot}/ordenes/crear`,
  //       roles: [],
  //       subs: [
  //         {
  //           icon: 'iconsminds-paper',
  //           label: 'Crear OC',
  //           to: `${adminRoot}/ordenes/crear/oc`,
  //           roles: [],
  //         },
  //         {
  //           icon: 'iconsminds-layer-backward',
  //           label: 'Crear OI',
  //           to: `${adminRoot}/ordenes/crear/oi`,
  //           roles: [],
  //         }
  //       ]
  //     },
  //   ],
  // },


  // **** Menu Embalaje **** //
/*   {
    icon: 'iconsminds-puzzle',
    label: 'menu.embalaje',
    to: `${adminRoot}/embalaje`,
    roles: [],
    subs: [
      {
        icon: 'iconsminds-monitor-analytics',
        label: 'Dashboard embalaje',
        to: `${adminRoot}/embalaje/dashboard`,
        roles: [],
      },
    ],
  }, */
  // *** Menú Comercial
  {
    icon: 'iconsminds-instagram',
    label: 'Comercial',
    to: `${adminRoot}/comercial`,
    roles: [rolComercial, rolSoporte, rolMapa, rolPedidos],
    subs:[
      {
        icon: 'simple-icon-question',
        label: 'Funnel',
        to: `${adminRoot}/comercial/funnel`,
        roles: [rolComercial],
      },
      {
        icon: 'iconsminds-money-bag',
        label: 'Panel de Comercial',
        to: `${adminRoot}/admin/panelcomercial`,
        roles: [rolComercial],
      },
      {
        icon: 'iconsminds-money-bag',
        label: 'Analisis distribuidores',
        to: `${adminRoot}/comercial/analisis-distribuidores`,
        roles: [rolComercial],
      },
      {
        icon: 'iconsminds-financial',
        label: 'Rot. de productos',
        to: `${adminRoot}/productos/rotacion-productos`,
        roles: [rolComercial, rolProductos],
      },
      {
        icon: 'iconsminds-calendar-4',
        label: 'Mapa',
        to: `${adminRoot}/comercial/mapa`,
        roles: [rolComercial, rolMapa, rolSoporte],
      },
      {
        icon: 'iconsminds-calendar-4',
        label: 'Crear clientes',
        to: `${adminRoot}/comercial/clientes`,
        roles: [rolComercial, rolSoporte, rolPedidos],
      },
      {
        icon: 'iconsminds-calendar-4',
        label: 'Registros clientes',
        to: `${adminRoot}/comercial/registros`,
        roles: [rolComercial, rolSoporte, rolPedidos],
      },
    ]
  },
  // *** Menu Finanzas ***//
  {
    icon: 'iconsminds-money-bag',
    label: 'Finanzas',
    to: `${adminRoot}/finanzas`,
    roles: [rolFinanzas, rolPresupuestos, rolFacturas, rolContador, rolCobrar],
    subs: [
      // {
      //   icon: 'iconsminds-monitor-analytics',
      //   label: 'Dashboard Finanzas',
      //   to: `${adminRoot}/finanzas/dashboard`,
      //   roles: [rolFinanzas],
      // },
      {
        icon: 'iconsminds-dollar',
        label: 'Flujo de efectivo',
        to: `${adminRoot}/finanzas/cuentas`,
        roles: [rolFinanzas, rolContador],
      },
      {
        icon: 'iconsminds-control',
        label: 'Panel de Finanzas',
        to: `${adminRoot}/admin/panelfinanzas`,
        roles: [rolPanelOperacion],
      },
/*       {
        icon: 'simple-icon-layers',
        label: 'Declaraciones',
        to: `${adminRoot}/finanzas/declaraciones`,
        roles: [rolFinanzas],
        subs: [
          {
            icon: 'iconsminds-line-chart-1',
            label: 'Flujo de efectivo',
            to: `${adminRoot}/finanzas/declaraciones/flujo-efectivo`,
            roles: [rolFinanzas],
          },
          {
            icon: 'iconsminds-scale',
            label: 'Resultados',
            to: `${adminRoot}/finanzas/declaraciones/resultados`,
            roles: [rolFinanzas],
          },
        ]
      }, */
      {
        icon: 'iconsminds-coins',
        label: 'Movimientos',
        to: `${adminRoot}/finanzas/movimientos/cuentas-internas`,
        roles: [rolFinanzas,rolContador],
      },
      {
        icon: 'iconsminds-mail-send',
        label: 'Facturas',
        to: `${adminRoot}/finanzas/facturacion/facturas`,
        roles: [rolFinanzas,rolContador, rolFacturas],
      },
      {
        icon: 'iconsminds-handshake',
        label: 'Cuentas por pagar',
        to: `${adminRoot}/finanzas/facturacion/cuentas-pagar`,
        roles: [rolFinanzas,rolContador],
      },
      {
        icon: 'iconsminds-handshake',
        label: 'Cuentas por cobrar',
        to: `${adminRoot}/finanzas/cobranza/cuentas-cobrar`,
        roles: [rolFinanzas,rolContador, rolCobrar],
      },
      {
        icon: 'iconsminds-billing',
        label: 'Presupuestos',
        to: `${adminRoot}/finanzas/presupuestos`,
        roles: [rolFinanzas,rolContador, rolPresupuestos],
      },
      {
        icon: 'iconsminds-billing',
        label: 'Estados Financieros',
        to: `${adminRoot}/finanzas/estados-financieros`,
        roles: [rolFinanzas,rolContador, rolPresupuestos],
      },
    ],
  },
  // **** Menu Proveedores **** //
  {
    icon: 'iconsminds-conference',
    label: 'Proveedores',
    to: `${adminRoot}/proveedores`,
    roles: [rolProveedores, rolCompras, rolFinanzas],
    subs:[
      {
        icon: 'iconsminds-box-with-folders',
        label: 'Portal',
        to: `${adminRoot}/proveedores/portal`,
        roles: [rolProveedores, rolCompras, rolFinanzas],
      },
      {
        icon: 'simple-icon-list',
        label: 'Lista',
        to: `${adminRoot}/proveedores/lista`,
        roles: [rolProveedores, rolCompras],
      },
    ]
  },
  {
    icon: 'iconsminds-conference',
    label: 'Pruebas',
    to: `${adminRoot}/pruebas`,
    roles: [],
    subs:[
      {
        icon: 'iconsminds-box-with-folders',
        label: 'Dashboards',
        to: `${adminRoot}/pruebas/dashboards`,
        roles: [],
      },
      {
        icon: 'iconsminds-test-tube',
        label: 'Pruebas',
        to: `${adminRoot}/pruebas/pruebas`,
        roles: [],
      },

    ]
  },

  // **** Menu Ajustes **** //
/*   {
    icon: 'iconsminds-gears',
    label: 'Ajustes',
    to: `${adminRoot}/ajustes`,
    roles: [],
    subs: [
      {
        icon: 'iconsminds-tablet-3',
        label: 'Configurar tablet',
        to: `${adminRoot}/ajustes/tablet`,
        roles: [],
      },
    ],
  }, */
  // **** Menu Comercial **** //
  // {
  //   icon: 'simple-icon-call-in',
  //   label: 'Comercial',
  //   to: `${adminRoot}/comercial`,
  //   roles: [, rolComercial],
  //   subs: [
  //     {
  //       icon: 'simple-icon-user-follow',
  //       label: 'Folios Clientes',
  //       to: `${adminRoot}/comercial/folios-clientes`,
  //       roles: [, rolComercial],
  //     },
  //   ],
  // },
];

export default data;
