import { Component, OnInit, OnDestroy, HostListener, Inject, TemplateRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { SidebarService, ISidebar } from '../sidebar/sidebar.service';
import { Router } from '@angular/router';
import { LangService, Language } from 'src/app/shared/lang.service';
import { AuthService } from 'src/app/shared/auth.service';
import { environment } from 'src/environments/environment';
import { getThemeColor, setThemeColor } from 'src/app/utils/util';
import { AuthCustomService } from '../../../shared/authCustom.service';
import { DOCUMENT } from '@angular/common';
import { ArgusApiService } from 'src/app/shared/services/api/argus-api.service';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-topnav',
  templateUrl: './topnav.component.html',
})
export class TopnavComponent implements OnInit, OnDestroy {
  buyUrl = environment.buyUrl;
  adminRoot = environment.adminRoot;
  sidebar: ISidebar;
  subscription: Subscription;
  displayName;
  languages: Language[];
  currentLanguage: string;
  isSingleLang;
  isFullScreen = false;
  isDarkModeActive = false;
  searchKey = '';

  date = new Date()

  notifications = []

  lastState
  modalRef: BsModalRef;

  empresas
  uid

  constructor(
    @Inject(DOCUMENT) private documentInjector: Document,
    private sidebarService: SidebarService,
    private authService: AuthService,
    private router: Router,
    private langService: LangService,
    private authCustom: AuthCustomService,
    private argusApiService: ArgusApiService,
    private notificationsService: NotificationsService,
    private modalService: BsModalService,
  ) {
    this.languages = this.langService.supportedLanguages;
    this.currentLanguage = this.langService.languageShorthand;
    this.isSingleLang = this.langService.isSingleLang;
    this.isDarkModeActive = getThemeColor().indexOf('dark') > -1 ? true : false;
  }

  openModal(template: TemplateRef<any>): void {
    this.modalRef = this.modalService.show(template, { class: 'second' });

  }


  onDarkModeChange(event): void {
    let color = getThemeColor();
    if (color.indexOf('dark') > -1) {
      color = color.replace('dark', 'light');
    } else if (color.indexOf('light') > -1) {
      color = color.replace('light', 'dark');
    }
    setThemeColor(color);
    setTimeout(() => {
      window.location.reload();
    }, 200);
  }

  fullScreenClick(): void {
    if (document.fullscreenElement) {
      document.exitFullscreen();
    } else {
      document.documentElement.requestFullscreen();
    }
  }

  @HostListener('document:fullscreenchange', ['$event'])
  handleFullscreen(event): void {
    if (document.fullscreenElement) {
      this.isFullScreen = true;
    } else {
      this.isFullScreen = false;
    }
  }

  onLanguageChange(lang): void {
    this.langService.language = lang.code;
    this.currentLanguage = this.langService.languageShorthand;
  }

  async ngOnInit(): Promise<void> {
    if (await this.authService.getUser()) {
      await this.authCustom.user$.subscribe((user) => {
        
        if(user) this.getNotifications()

        this.displayName =  user.displayName;

        this.empresas = user.companies
        this.uid = user.uid
      });
    }
    this.subscription = this.sidebarService.getSidebar().subscribe(
      (res) => {
        this.sidebar = res;
      },
      (err) => {
        console.error(`An error occurred: ${err.message}`);
      }
    );
  }

  changeEmpresa(evt){
    console.log('this.empresas :>> ', this.empresas);
    console.log('evt :>> ', evt);
    console.log('this.uid :>> ', this.uid);
    this.argusApiService.updateDoc('users', this.uid, {
      companies: this.empresas
    }).then((promise)=>{
      promise.subscribe((obs) => {
        console.log(obs);
        this.notificacion('Listo','Ajustes guardados exitosamente.')
      });
    });
  }

  getNotifications(){

    if(environment.notifications){

          this.argusApiService.getNotificationsUser().then(promise=>{
            promise.subscribe((obs)=>{
      
              
              this.notifications = obs.result
              if(!this.lastState) this.lastState = obs.result
              else this.checkNewNotifications()
            })
          })
    }
  }

  checkNewNotifications(){

    this.notifications.forEach(element => {

      if(this.lastState.findIndex(x=>x._id == element._id) == -1){

        
        this.notificacion(element.title, element.text)
      }

    });

    this.lastState = this.notifications

  }

  readNotification(id){

    this.argusApiService.readNotification(id).then((promise)=>{

      promise.subscribe((obs)=>{
        this.getNotifications()


      })

    })


  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  menuButtonClick = (
    e: { stopPropagation: () => void },
    menuClickCount: number,
    containerClassnames: string
  ) => {
    if (e) {
      e.stopPropagation();
    }

    setTimeout(() => {
      const event = document.createEvent('HTMLEvents');
      event.initEvent('resize', false, false);
      window.dispatchEvent(event);
    }, 350);

    this.sidebarService.setContainerClassnames(
      ++menuClickCount,
      containerClassnames,
      this.sidebar.selectedMenuHasSubItems
    );
  }

  mobileMenuButtonClick = (
    event: { stopPropagation: () => void },
    containerClassnames: string
  ) => {
    if (event) {
      event.stopPropagation();
    }
    this.sidebarService.clickOnMobileMenu(containerClassnames);
  }

  onSignOut(): void {
    this.authService.signOut().subscribe(() => {
      this.router.navigate(['/user/login']);
    });
  }

  searchKeyUp(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      this.search();
    } else if (event.key === 'Escape') {
      const input = document.querySelector('.mobile-view');
      if (input && input.classList) {
        input.classList.remove('mobile-view');
      }
      this.searchKey = '';
    }
  }

  reload(): void {

    this.documentInjector.defaultView.location.reload();

  }

  searchAreaClick(event): void {
    event.stopPropagation();
  }
  searchClick(event): void {
    if (window.innerWidth < environment.menuHiddenBreakpoint) {
      let elem = event.target;
      if (!event.target.classList.contains('search')) {
        if (event.target.parentElement.classList.contains('search')) {
          elem = event.target.parentElement;
        } else if (
          event.target.parentElement.parentElement.classList.contains('search')
        ) {
          elem = event.target.parentElement.parentElement;
        }
      }

      if (elem.classList.contains('mobile-view')) {
        this.search();
        elem.classList.remove('mobile-view');
      } else {
        elem.classList.add('mobile-view');
      }
    } else {
      this.search();
    }
    event.stopPropagation();
  }

  search(): void {
    if (this.searchKey && this.searchKey.length > 1) {
      this.router.navigate([this.adminRoot + '/#'], {
        queryParams: { key: this.searchKey.toLowerCase().trim() },
      });
      this.searchKey = '';
    }
  }

  @HostListener('document:click', ['$event'])
  handleDocumentClick(event): void {
    const input = document.querySelector('.mobile-view');
    if (input && input.classList) {
      input.classList.remove('mobile-view');
    }
    this.searchKey = '';
  }

  logout(): void {
    this.authCustom.logout();
  }

  notificacion(title, message): void{
    this.notificationsService.create(title, message,
      NotificationType.Success, { theClass: 'ouline primary', timeOut: 10000, showProgressBar: true });
  }
  notificacionError(title, message): void{
    this.notificationsService.create(title, message,
      NotificationType.Error, { theClass: 'ouline danger', timeOut: 10000, showProgressBar: true });
  }


  
}
