<nav class="navbar fixed-top">
    <div class="d-flex align-items-center navbar-left">
        <a (click)="menuButtonClick($event, sidebar.menuClickCount, sidebar.containerClassnames); false;" href="javascript:;" class="menu-button d-none d-md-block">
            <svg class="main" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9 17">
        <rect x="0.48" y="0.5" width="7" height="1" />
        <rect x="0.48" y="7.5" width="7" height="1" />
        <rect x="0.48" y="15.5" width="7" height="1" />
      </svg> {{" "}}
            <svg class="sub" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 17">
        <rect x="1.56" y="0.5" width="16" height="1" />
        <rect x="1.56" y="7.5" width="16" height="1" />
        <rect x="1.56" y="15.5" width="16" height="1" />
      </svg>
        </a>

        <a (click)="mobileMenuButtonClick($event, sidebar.containerClassnames)" href="javascript:;" class="menu-button-mobile d-xs-block d-sm-block d-md-none">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 17">
        <rect x="0.5" y="0.5" width="25" height="1" />
        <rect x="0.5" y="7.5" width="25" height="1" />
        <rect x="0.5" y="15.5" width="25" height="1" />
      </svg>
        </a>

        <!-- <div class="search" (click)="searchAreaClick($event)">
            <input placeholder="Search..." [(ngModel)]="searchKey" (keyup)="searchKeyUp($event)">
            <span class="search-icon" (click)="searchClick($event)">
        <i class="simple-icon-magnifier"></i>
      </span>
        </div> -->

        <!-- <div class="btn-group ml-2" dropdown *ngIf="!isSingleLang">
            <button dropdownToggle type="button" class="language-button dropdown-toggle btn btn-light btn-sm">
        {{currentLanguage | uppercase}} <span class="caret"></span>
      </button>
            <ul *dropdownMenu class="dropdown-menu" role="menu">
                <li role="menuitem" *ngFor="let item of languages" (click)="onLanguageChange(item)"><a class="dropdown-item" href="javascript:;">{{item.label}}</a></li>
            </ul>
        </div> -->

        <!-- <a class="btn btn-sm btn-outline-primary ml-2 d-none d-xl-inline-block" [href]="buyUrl" target="_blank">&nbsp;BUY&nbsp;</a> -->


    </div>

    <a class="navbar-logo" [routerLink]="adminRoot">
        <span class="logo logo-{{isDarkModeActive ? 'dark' : 'light'}} d-none d-xs-block "></span>
        <span class="logo-mobile logo-mobile-{{isDarkModeActive ? 'dark' : 'light'}} d-block d-xs-none"></span>
    </a>

    <div class="navbar-right">
        <div class="header-icons d-inline-block align-middle">
            <div class="header-icon  d-none d-sm-inline-block">
                <i *ngIf="!isDarkModeActive" class="iconsminds-half-moon" style="color: #0d169f;"></i>
                <i *ngIf="isDarkModeActive" class="iconsminds-sun" style="color: #F5BB00;"></i>
            </div>

            <div class="d-none d-md-inline-block align-text-bottom mr-1">
                <div class="custom-control custom-switch cursor-pointer" tooltip="{{isDarkModeActive ? 'Modo claro':'Modo oscuro'}}" placement="left">

                    <input type="checkbox" class="custom-control-input" id="darkModeSwitch" [checked]="isDarkModeActive" (change)="onDarkModeChange($event)">
                    <label class="custom-control-label" for="darkModeSwitch"></label>
                </div>
            </div>
            <!--
      <div class="position-relative d-none d-sm-inline-block">
        <div dropdown>
          <button dropdownToggle type="button" class="header-icon btn btn-empty">
            <i class="simple-icon-grid"></i>
          </button>
          <div *dropdownMenu class="dropdown-menu dropdown-menu-right mt-3" role="menu" aria-labelledby="button-basic"
            id="iconMenuDropdown">
            <a href="javascript:;" class="icon-menu-item">
              <i class="iconsminds-equalizer d-block"></i>
              <span>Settings</span>
            </a>

            <a href="javascript:;" class="icon-menu-item">
              <i class="iconsminds-male-female d-block"></i>
              <span>Users</span>
            </a>

            <a href="javascript:;" class="icon-menu-item">
              <i class="iconsminds-puzzle d-block"></i>
              <span>Components</span>
            </a>

            <a href="javascript:;" class="icon-menu-item">
              <i class="iconsminds-bar-chart-4 d-block"></i>
              <span>Profits</span>
            </a>

            <a href="javascript:;" class="icon-menu-item">
              <i class="iconsminds-file d-block"></i>
              <span>Surveys</span>
            </a>

            <a href="javascript:;" class="icon-menu-item">
              <i class="iconsminds-suitcase d-block"></i>
              <span>Tasks</span>
            </a>
          </div>
        </div>
      </div>
    -->
      <div class="position-relative d-inline-block">

        <div dropdown>
          <button id="button-basic" dropdownToggle type="button" id="notificationButton"
            class="header-icon btn btn-empty">
            <i class="simple-icon-bell"></i>
            <span class="count badge-danger text-white border-danger" *ngIf="notifications.length">{{notifications.length}}</span>
          </button>
          <div id="dropdown-basic" *dropdownMenu class="dropdown-menu dropdown-menu-right mt-3 pl-2 pr-0"
            id="notificationDropdown">
            <perfect-scrollbar>
              <div class="d-flex flex-row justify-content-between mb-3 pb-3 border-bottom" *ngFor="let item of notifications">
                <div class="d-flex flex-row">

                  <a [href]="item.url">
                    <img src="/assets/img/argus/default-user.png" alt="Notification Image"
                      class="img-thumbnail list-thumbnail xsmall border-0 rounded-circle" />
                  </a>
                  <div class="pl-3">
                    <a [href]="item.url">
                      <p class="bold mb-1">{{ item.title }}</p>
                      <p class="font-weight-medium mb-1">{{item.text}}</p>
                      <p class="text-muted mb-0 text-small">{{ item.createdAt | date: 'M/dd/yy h:mm a' }}</p>
                    </a>
                  </div>
                </div>
                <div class="d-flex align-items-center ml-2" (click)="$event.stopPropagation()">
                  <button class="btn btn-secondary icon-button shadow-icon text-small" (click)="readNotification(item._id)">
                    <i class="fas fa-check"></i>
                  </button>
                </div>
              </div>
            </perfect-scrollbar>
          </div>
        </div>
      </div>
      


            <button class="header-icon btn btn-empty d-none d-sm-inline-block" type="button" id="fullScreenButton" (click)="fullScreenClick()">
        <i *ngIf="!isFullScreen" class="simple-icon-size-fullscreen"></i>
        <i *ngIf="isFullScreen" class="simple-icon-size-actual"></i>
      </button>

        </div>

        <div class="user d-inline-block" dropdown>
            <button class="btn btn-empty p-0" type="button" dropdownToggle>
        <span class="name">{{displayName}}</span>
        <span class="" style="position: relative;">

          <!-- Halloween -->
          <img alt="Profile Picture" src="/assets/icons/happy-new-year.svg" />
          <!-- <img src="/assets/icons/witch-hat.svg" alt="" class="icon-profile"> -->
        </span>
      </button>

            <div class="dropdown-menu dropdown-menu-right mt-3" *dropdownMenu role="menu">
                <a class="dropdown-item c-pointer" routerLink="/app/cuenta">{{ displayName }}</a>
                <li class="divider dropdown-divider"></li>
                <a class="dropdown-item c-pointer" (click)="logout()">Cerrar sesión</a>
                <a class="dropdown-item c-pointer" (click)="openModal(empresasModal)">Empresas</a>
                <a class="dropdown-item c-pointer" (click)="reload()">Recargar ARGUS</a>
            </div>
        </div>

    </div>
</nav>
<ng-template #empresasModal>
  <div class="modal-container">

      <div class="modal-header">

          <span class="text-center size-2 w-100">Empresas activas</span>

          <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
              <span aria-hidden="true">&times;</span>
          </button>

      </div>
      <div class="modal-body">

          <div class="d-flex flex-column align-items-start m-0 p-0 gap-0-5rem">

            <div class="custom-control custom-checkbox pl-1 pr-4" *ngFor="let empresa of empresas">
              <label class="align-items-center custom-checkbox custom-control d-flex mb-0">
                  <input type="checkbox" class="custom-control-input" name="{{empresa.handle}}" [(ngModel)]="empresa.active" (ngModelChange)="changeEmpresa($event)" >
                  <span class="custom-control-label size-3" >
                    {{empresa.label}}
                  </span>
              </label>
            </div>

          </div>

      </div>
  </div>
</ng-template>
<simple-notifications></simple-notifications>

