import { Injectable } from '@angular/core';
import { Router} from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';

import { BehaviorSubject, Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { initializeApp, User, auth } from 'firebase';

import {
  HttpClient,
  HttpHeaders,
  HttpParams
} from '@angular/common/http';
import { environment } from 'src/environments/environment';


interface UserType {
  uid: string;
  email: string;
  photoURL?: string;
  displayName?: string;
  roles?: Roles;
  area: string;
  
}

interface Roles{
  guest?: boolean;
  editor?: boolean;
  admin?: boolean;
}

@Injectable({
  providedIn: 'root'
})

export class AuthCustomService {
  private eventAuthError = new BehaviorSubject<string>('');
  canLogin;
  eventAuthError$ = this.eventAuthError.asObservable();
  user$: Observable<any>;
  // config = {
  //   apiKey: 'AIzaSyDakuvksTb9JOE_VtA7vVKyl68ChmuZrlE',
  //   authDomain: 'cv-argus.firebaseapp.com',
  //   databaseURL: 'https://cv-argus.firebaseio.com',
  // };
  config = {
    apiKey: 'AIzaSyDDlMYDk2Mlh4M94SwSknkzqm-TOsI3Q6s',
    authDomain: 'cv-argus3.firebaseapp.com',
    databaseURL: 'https://cv-argus3.firebaseio.com',
};
  secondaryApp = initializeApp(this.config, 'secondary');
  private headers = {};

  private url;
  local = true;

  constructor(
    private http: HttpClient,
    private afAuth: AngularFireAuth,
    private db: AngularFirestore,
    private router: Router,


    ) {
    this.url = environment.local ? environment.argus.apiUrlLocal : environment.argus.apiUrl;

    this.user$ = this.afAuth.authState.pipe(
      switchMap(user => {
        // logged in
        if (user){
          return this.db.doc<UserType>(`users/${user.uid}`).valueChanges();
        }else{
          return of(null);
        }
      })
    );
  }


  ///// Email & Password Authorization //////

  getUser(): Observable<any>{
    return this.user$;
  }

  getUserState(): Observable<User> {
    return this.afAuth.authState;
  }

  login(email: string, password: string): void {
    this.afAuth.signInWithEmailAndPassword(email, password)
      .catch(error => {
        this.eventAuthError.next(error);
      })
      .then(userCredential => {

        if (userCredential) {
          this.router.navigate(['/app']);
          // this.user$.subscribe((user) => {
          //   this.headers = {
          //     Authorization: `Bearer ${user.uid}`,
          //   };

          //   const endpoint = `/api/general/login`;

          //   this.http.get<any>(    environment.local ? this.url + endpoint:
    //  this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'), {headers: this.headers}).subscribe((result) => {

          //   });
            // this.http.get<any>(this.url + `/api/general/getDeviceName`, {headers: this.headers}).subscribe((resultDevice) => {

          //     console.log('resultDevice :>> ', resultDevice);
          //     localStorage.setItem('device', JSON.stringify(resultDevice));
          //   });

          // });
        }
      });
  }

  createUser(user: any): void {
    // console.log(user);
    this.afAuth.createUserWithEmailAndPassword( user.email, user.password)
      .then( userCredential => {
        userCredential.user.updateProfile( {
          displayName: user.firstName + ' ' + user.lastName,
        });

        this.insertUserData(userCredential, user.firstName + ' ' + user.lastName)
          .then(() => {
            this.router.navigate(['/app']);
          });
      })
      .catch( error => {
        this.eventAuthError.next(error);
      });
  }

  insertUserData(userCredential: auth.UserCredential, name: string): Promise<void> {
    const data = {
      uid: userCredential.user.uid,
      displayName: name,
      email: userCredential.user.email,
      roles: {
        guest: true,
      },
    };

    return this.db.doc(`users/${userCredential.user.uid}`).set(data, {merge: true});
  }

  createOperator(user: any): void {

    const pwd = user.correo.split("@")[0]+'Verde.'
    this.secondaryApp.auth().createUserWithEmailAndPassword( user.correo,pwd )
      .then( userCredential => {
        // userCredential.user.updateProfile( {
        //   displayName: `${user.nombre} ${user.apellidoPaterno}`,

        // });

        this.insertOperatorData(userCredential, user.nombre, user.apellidoPaterno, user.apellidoMaterno, user.area, user.id, user.horario, user.mesa, user.estacion).then((result) => {
          
        }).catch((err) => {
          
        });
        this.secondaryApp.auth().signOut();
      })
      .catch( error => {
        this.eventAuthError.next(error);
      });
  }

  insertOperatorData(userCredential: auth.UserCredential,
                     name: string,
                     apellidoPaterno: string,
                     apellidoMaterno: string,
                     userArea: string,
                     idNumber: string,
                     horario: string,
                     mesa?:number,
                     estacion?:number
                     ): Promise<void> {


    const data = {
      area: userArea,
      active: false,
      uid: userCredential.user.uid,
      displayName: `${name} ${apellidoPaterno}`,
      nombres: name,
      apellidoPaterno,
      apellidoMaterno,
      horario,
      email: userCredential.user.email,
      roles: {
        [userArea]: true,
        turnos: true
      },
      mesa: mesa*1 || null,
      estacionAsignada: estacion*1 || null,
      id: idNumber
    };


    return this.db.doc(`users/${userCredential.user.uid}`).set(data, {merge: true});

    // return this.http.post<any>(    environment.local ? this.url + endpoint:
    //  this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'), data , {headers: this.headers } ).subscribe((obs)=>{
    //   console.log(obs);
      
    // })
  
    // return true

    // console.log(data)

  }

  logout(): Promise<void> {
    // this.router.navigate(['/user/login']);
    // this.user$.subscribe((user) => {
    //   this.headers = {
    //     Authorization: `Bearer ${user.uid}`,
    //   };

    //   const endpoint = `/api/general/logout`;

    //   this.http.get<any>(    environment.local ? this.url + endpoint:
    //  this.url.replace('functionName', endpoint.split('/')[1] )+ '/' + endpoint.split('/').slice(2).join('/'), {headers: this.headers}).subscribe((result) => {

    //   });
    // });

    
    window.location.href = '/user/login'
    
    
    return this.afAuth.signOut(); 
    
  }

  ///// Role-based Authorization //////

  canRead(user: UserType): boolean {
    const allowed = ['admin', 'editor', 'guest'];
    return this.checkAuthorization(user, allowed);
  }

  canEdit(user: UserType): boolean {
    const allowed = ['admin', 'editor'];
    return this.checkAuthorization(user, allowed);
  }

  canDelete(user: UserType): boolean {
    const allowed = ['admin'];
    return this.checkAuthorization(user, allowed);
  }

  canOperar(user: UserType): boolean {
    const allowed = [
    'admin',
    'almacen_pt',
    'almacen_pt_manual',
    'embalaje',
    'almacen_general',
    'finanzas',
    'presupuestos',
    'facturas',
    'ecc',
    'produccion',
    'sellado',
    'soporte',
    'supervision_produccion',
    'contador',
    'casa_verde',
    'paneloperacion',
    'chofer',
    'proveedor',
    'proveedores',
    'autoservicio',
    'paquetes',
    'comercial',
  
  ];
    return this.checkAuthorization(user, allowed);
  }

  // determines if user has matching role
  private checkAuthorization(user: UserType, allowedRoles: string[]): boolean {
    if (!user) { return false; }
    for (const role of allowedRoles) {
      if ( user.roles[role] ) {
        return true;
      }
    }
    return false;
  }

  ///// Miscellaneous functions //////

}

 /*
  ///// Google Sign In //////
  async googleSignin() {
    const provider = new firebase.auth.GoogleAuthProvider();
    const credential = await this.afAuth.signInWithPopup(provider);
    return this.updateUserData(credential.user);
  }

  private updateUserData(user: firebase.User) {
    const userRef: AngularFirestoreDocument<User> = this.afs.doc(`users/${user.uid}`);

    const data = {
        uid: user.uid,
        displayName: user.displayName,
        email: user.email,
        photoURL: user.photoURL,
        roles: {
          guest: true
        }
    };
    return userRef.set(data, {merge: true});
  }

  async signOut(){
    await this.afAuth.signOut();
    this.router.navigate(['/']);
  }
*/
